<template>
	<div class="relative" tabindex="0" @blur="active = false">
		<div
			:class="{
				'active-state': active,
				disabled,
				filled: !!internalValue
			}"
			class="select-element"
		>
			<span v-if="internalValue" class="description">
				{{ label || chooseText || 'bitte auswählen...' }}
				<span v-if="required" :class="{ required: !internalValue }">*</span>
			</span>
			<label
				class="p-3 flex items-center"
				:class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
				@click="!active ? getValues() : resetVal()"
			>
				<span
					v-if="internalValue && hasValue(internalValue) && internalValue.value"
					class="flex-auto input"
				>
					{{ internalValue.value }}
				</span>
				<span v-else class="flex-auto input">
					{{ chooseText || 'bitte auswählen...' }}
					<span v-if="!internalValue && required" :class="{ required: !internalValue }">*</span>
				</span>
				<HokIcon
					v-if="!active"
					name="icon:arrow-down"
					:size="4"
					:color="disabled ? 'grey-light' : 'default'"
				/>
				<HokIcon v-if="active" name="icon:arrow-up" :size="4" color="main" pointer />
			</label>
		</div>
		<ul
			v-if="active"
			:class="{ 'active-state': !!active, 'h-[235px]': limitDropdownHeight }"
			class="shadow-lg rounded-b-lg overflow-hidden bg-color-white w-full z-10 absolute overflow-y-auto select-wrapper"
		>
			<Spinner v-if="loading" :fixed="false" />
			<li
				v-for="(option, index) in values"
				:key="option.id || index"
				:class="{
					'bg-color-grey-lightest':
						option.value === (internalValue && hasValue(internalValue) && internalValue.value)
				}"
				class="p-3 hover:bg-color-grey-lightest border-color-grey-lightest border-b cursor-pointer"
				@click="setCurrentVal($event, option)"
			>
				<slot name="item" :item="option" :value="internalValue">
					{{ option.value }}
				</slot>
			</li>
		</ul>
		<input
			v-if="internalValue && hasId(internalValue) && internalValue.id"
			type="hidden"
			:value="internalValue.id"
			:name="submitValue"
		/>
		<ErrorBox v-if="error">
			Die Auswahl kann zurzeit nicht geladen werden. Bitte versuch es später noch einmal!
			{{ error }}
		</ErrorBox>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HokIcon from './HokIcon.vue';
import Spinner from './Spinner.vue';
import ErrorBox from './ErrorBox.vue';

export interface IValue {
	id?: string | number;
	value: string;
	params?: any;
}

export default defineComponent({
	name: 'HokSelect',
	components: { HokIcon, Spinner, ErrorBox },
	emits: ['input'],
	data() {
		const internalValue = (this.value && { value: this.value }) as IValue | string | undefined;

		const values: IValue[] = [];

		return {
			internalValue,
			loading: false,
			active: false,
			values,
			error: false
		};
	},
	async created() {
		if (this.value) {
			await this.loadValues();
			this.setVal(this.value);
		}
	},
	methods: {
		hasId(object: any): object is { id: string } {
			return typeof object === 'object' && 'id' in object;
		},
		hasValue(object: any): object is { value: string } {
			return typeof object === 'object' && 'value' in object;
		},
		resetVal(val?: string) {
			if (!val) {
				val = this.value;
			}
			this.internalValue =
				this.values?.find(v => v.value === val) ?? (this.value && { value: this.value });
			this.active = false;
		},
		setVal(val) {
			this.resetVal(val);
		},
		setCurrentVal($event, option) {
			this.internalValue = option;
			this.active = false;
			this.$emit('input', option, $event, { keyName: this.keyName });
		},
		async loadValues() {
			this.loading = true;
			try {
				this.values = await this.valuePromise();
				this.error = false;
			} catch (err: any) {
				console.error('err', err);
				this.error = err;
			}
			this.loading = false;
		},
		async getValues() {
			if (this.disabled) {
				return;
			}

			this.loading = true;
			this.active = true;
			try {
				this.values = await this.valuePromise();
				this.error = false;
			} catch (err: any) {
				console.error('err', err);
				this.active = false;
				this.error = err;
			}
			this.loading = false;
		},
		onValueChanged(newVal) {
			if (!newVal || newVal.length === 0) {
				this.resetVal();
			} else {
				this.setVal(newVal);
			}
		}
	},
	props: {
		keyName: { type: String, default: '' },
		value: { type: String, default: '' },
		chooseText: { type: String, default: '' },
		label: { type: String, default: '' },
		submitValue: { type: String, default: '' },
		required: { type: Boolean, default: false },
		limitDropdownHeight: { type: Boolean, default: false },
		valuePromise: { type: Function, required: true },
		disabled: { type: Boolean, default: false }
	},
	watch: {
		value: [
			{
				handler: 'onValueChanged'
			}
		]
	}
});
</script>

<style lang="scss" scoped>
.required {
	color: #e74c3c;
}
.select-wrapper {
	/* exact height of 8 items */
	max-height: 376px;

	@media (max-height: 736px) {
		/* exact height of 4 items */
		max-height: 188px;
	}
}

.select-element {
	position: relative;
	max-width: 400px;
	line-height: 1.5;
	border-radius: 3px;
	border: 1px solid $color-grey-light;

	&.active-state {
		border: 1px solid #0fb1af;
		span {
			color: #0fb1af;
		}
	}

	.input {
		color: $color-grey-medium;
		font-weight: bold;
		-webkit-font-smoothing: antialiased;
	}

	&.filled {
		border: 1px solid $color-blue-grey;
		span {
			color: $color-blue-grey;
		}
		.input {
			color: $color-blue-grey;
			font-weight: 500;
			-webkit-font-smoothing: antialiased;
		}
	}

	&.disabled {
		border: 1px solid $color-grey-light;
		cursor: not-allowed;
		span {
			color: $color-grey-light;
		}
	}

	.description {
		position: absolute;
		background: #ffffff;
		padding-left: 4px;
		padding-right: 4px;
		margin-top: -11px;
		font-size: 12px;
		margin-left: 4px;
		color: $color-grey-medium;
		font-weight: bold;
	}
}
ul {
	margin-top: 1px;
	max-width: 400px;
}
</style>
