<template>
	<div :class="[myClasses, { 'opacity-50': disabled }]" class="input input--sae mb-4">
		<label
			v-if="$slots.default"
			:for="id"
			:class="{ 'styling-white': stylingWhite }"
			class="input__label input__label--sae"
		>
			<span
				class="input__label-content input__label-content--sae"
				:class="{ 'styling-white': stylingWhite }"
			>
				<slot />
				<!-- For white styling we only want the asterisk to be white when not focused -->
				<span
					v-if="required"
					:class="{ required: !value, 'styling-white': stylingWhite && !focused }"
					>*</span
				>
			</span>
		</label>
		<HokInputWrapper :wrap="type === 'password'" :focused="focused" :value="value">
			<input
				:id="id"
				ref="input"
				:class="{
					'typeform-input': styling === 'typeform',
					'text-center': styling === 'text-center',
					'bg-white': styling === 'bg-white',
					'cursor-not-allowed': disabled,
					'styling-white': stylingWhite,
					'hide-border': type === 'password'
				}"
				:value="value"
				:type="showPassword ? 'text' : type"
				:minlength="minLength"
				:maxlength="maxLength"
				:max="max"
				:min="min"
				:pattern="pattern"
				:inputmode="inputmode"
				:name="name"
				:autocomplete="!browserAutocomplete ? 'off' : browserAutocomplete"
				:placeholder="placeholder"
				:required="required"
				:disabled="disabled"
				class="input__field input__field--sae input"
				@blur.prevent.stop="blur"
				@click.prevent.stop="$emit('click', $event)"
				@input.prevent.stop="onChange"
				@focus="focus"
				@keydown.enter="$emit('enter')"
			/>
			<div v-if="type === 'password'" class="p-3 cursor-pointer" @click="togglePassword">
				<client-only>
					<HokIcon :icon="showPassword ? 'icon:icon-view-hide' : 'icon:icon-view'" />
				</client-only>
			</div>
		</HokInputWrapper>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HokInputWrapper from './HokInputWrapper.vue';

export default defineComponent({
	name: 'HokInput',
	components: { HokInputWrapper },
	data() {
		return {
			focused: false,
			showPassword: false
		};
	},
	computed: {
		myClasses() {
			const classes: string[] = [];
			if (this.focused || typeof this.value === 'number' || (this.value && this.value.length > 0)) {
				classes.push('input--filled');
			}
			if (this.focused) {
				classes.push('input--active');
			}
			if (this.fullwidth) {
				classes.push('max-w-full');
			}
			if (this.styling === 'white') {
				classes.push('styling-white');
			}
			return classes;
		},
		stylingWhite(): boolean {
			return this.styling === 'white';
		},
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit('update:modelValue', value);
			}
		}
	},
	created() {
		if (this.id === 'hokinput') {
			console.warn('HokInput without explicit id', this);
		}
	},
	methods: {
		hasValue(object: any): object is { value: string } {
			return typeof object === 'object' && 'value' in object;
		},
		focus($event) {
			this.focused = true;
			this.$emit('focus', $event);
		},
		blur($event) {
			this.focused = false;
			this.$emit('blur', $event);
		},
		onChange($event) {
			this.value = $event.target.value ?? '';
			this.$emit('input', this.value);
		},
		togglePassword() {
			this.showPassword = !this.showPassword;
		}
	},
	props: {
		name: { type: String, default: '' },
		modelValue: { type: [String, Number], default: '' },
		minLength: { type: Number },
		maxLength: { type: Number },
		max: { type: Number },
		min: { type: Number },
		inputmode: { type: String },
		pattern: { type: String },
		styling: { type: String, default: '' },
		id: { type: String, default: 'hokinput', required: true },
		browserAutocomplete: { type: [Boolean, String], default: 'on' },
		required: { type: Boolean, default: false },
		placeholder: { type: String, default: '' },
		type: { type: String, default: 'text' },
		autofocus: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		fullwidth: {
			type: [String, Boolean],
			default: false,
			validator: (value: string | boolean) => [false, 'mobile', 'always'].includes(value)
		}
	},
	emits: ['input', 'focus', 'blur', 'click', 'enter', 'update:modelValue']
});
</script>

<style scoped src="../styles/input.scss" />
<style lang="scss" scoped>
.bg-white {
	background: white;
}
</style>
