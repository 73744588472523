import { errorCodes } from '@hokify/shared-components-nuxt3/lib/data/errorCodes';

export default defineNuxtPlugin(nuxtApp => {
	// if customMessage is set to null, no snack will be shown at all
	const {
		public: { development }
	} = useRuntimeConfig();
	const errorHandler = (err: any, customMessage?: string | null) => {
		if (development) {
			console.error('$errorHandler', customMessage, {
				nuxtApp,
				err
			});
		}

		if (nuxtApp.deviceOffline) {
			nuxtApp.$snack?.danger({
				title: 'Keine Internetverbindung!',
				text: 'Bitte überprüfe deine Verbindung zum Internet.'
			});
		} else if (err?.code === 'ECONNABORTED') {
			nuxtApp.$snack?.danger({
				title: 'Verbindung unterbrochen!',
				text: 'Bitte überprüfe deine Internetverbindung.'
			});
		} else if (/^Loading chunk (\d)+ failed\./.test(err?.message || err) && import.meta.client) {
			// eslint-disable-next-line
			const pathForReload = process.env.pathForReload;
			try {
				nuxtApp.$snack?.danger({
					text: 'Alte Version entdeckt oder anderer schwerwiegender Fehler, bitte hokify neu laden!',
					button: 'Neu laden',
					forever: true,
					action: () => {
						if (pathForReload && typeof pathForReload === 'string') {
							window.location.href = pathForReload;
						} else {
							window.location.reload();
						}
					}
				});
			} catch (errSnack) {
				if (nuxtApp.$sentry) {
					nuxtApp.$sentry.captureException(errSnack as Error);
				}
				console.error('Chunk failed, fallback error handler failed. Forcing reload...', err);
				if (pathForReload && typeof pathForReload === 'string') {
					window.location.href = pathForReload;
				} else {
					window.location.reload();
				}
			}
		} else {
			// decide if we should log this error to sentry
			if (
				err &&
				nuxtApp.$sentry &&
				// if network error status code > 499 or 422 (invalid parameters)
				(!err.statusCode || err.statusCode > 499 || err.statusCode === 422)
			) {
				nuxtApp.$sentry.withScope(scope => {
					// not sure if setExtra is really necessary because we already have them directly in the error?
					scope.setExtra('requestUrl', err.request);
					scope.setExtra('requestMethod', err.options?.method);
					scope.setExtra('responseStatusCode', err.statusCode);
					nuxtApp.$sentry.captureException(err);
				});
			}

			const errorObj = err && (err.data || err.error || err);
			nuxtApp.$snack?.danger({
				text:
					(errorObj && errorCodes.genericErrors[errorObj.code]) ||
					customMessage ||
					errorObj?.message ||
					(errorObj?.code &&
						`Es ist ein Fehler aufgetreten. ${
							(errorObj.code && ` (Fehlercode: ${errorObj.code})`) || ''
						}`) ||
					err?.message ||
					(err?.code &&
						`Es ist ein Fehler aufgetreten. ${
							(err && err.code && ` (Fehlercode: ${err.code})`) || ''
						}`) ||
					'Seite konnte nicht geladen werden.'
			});
		}
	};

	return {
		provide: {
			errorHandler
		}
	};
});
