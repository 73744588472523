<template>
	<div class="hok-login" data-cy="loginDialog">
		<div class="m-auto">
			<div
				class="controls flex flex-wrap"
				:class="{
					'pb-12': standalone,
					'overflow-x-hidden': companyMode && state === LoginState.signup && !isWebsiteCase
				}"
			>
				<HeaderOnboarding
					v-if="showHeader && (standalone || !$isMobile.any)"
					:company-mode="companyMode"
					class="mb-8"
				>
				</HeaderOnboarding>
				<div class="container max-w-sm" :class="{ 'px-0': !standalone }">
					<h2
						v-if="state !== LoginState.loading"
						class="smaller"
						:class="{ 'text-center': standalone }"
						@click="switchEnv"
					>
						<slot name="headline">
							<template v-if="newPasswordSet">
								<NuxtImg
									v-if="companyMode"
									class="mx-auto mb-6"
									src="/shared-components-nuxt3/svgs/company_confetti.svg"
								/>
								<NuxtImg
									v-else
									class="mx-auto mb-6"
									src="/shared-components-nuxt3/svgs/confetti.svg"
								/>
								Geschafft!
							</template>
							<template v-else>{{
								state === LoginState.signup
									? `${companyMode ? 'Über hokify erfolgreich Mitarbeiter:innen finden' : 'Ein Profil. Eine Anmeldung. Alle Jobs.'}`
									: 'Willkommen zurück!'
							}}</template>
						</slot>
					</h2>
					<div
						v-if="cordova && baseURL && baseURL.includes('test')"
						class="text-center bg-color-purple px-2 m-auto rounded-full text-color-white font-bold"
						style="font-size: x-small; left: 2.4rem; top: 0.3rem"
					>
						TEST
					</div>
					<p
						v-if="state === LoginState.login || state === LoginState.signup"
						class="text-center"
						:class="{ 'mb-8': state === LoginState.signup && companyMode }"
					>
						<template v-if="state === LoginState.login">
							<template v-if="newPasswordSet">
								{{
									companyMode
										? 'Melden Sie Sich jetzt mit Ihrem Profil an:'
										: 'Melde dich jetzt mit deinem Profil an:'
								}}
							</template>
							<template v-else>
								Noch kein {{ companyMode ? 'Unternehmensprofil' : 'Profil' }}?
								<HokButton
									is-text
									:color="companyMode ? 'business' : 'main'"
									@click="resume(hasEmptyPayload({ state: LoginState.landing }))"
									>Jetzt registrieren!</HokButton
								>
							</template>
						</template>
						<template v-else
							>{{
								companyMode
									? 'Jetzt kostenlos hokify Firmenkonto erstellen.'
									: 'Jetzt kostenlos anmelden und dein hokify Profil für alle Bewerbungen verwenden.'
							}}
						</template>
					</p>
					<keep-alive include="HokSignupFormB2B">
						<Spinner v-if="state === LoginState.loading" :fixed="standalone" />
						<div v-else-if="state === LoginState.login">
							<HokLoginForm
								:current-login="currentLogin"
								:privacy="requiredPrivacy"
								:current-login-state="state"
								:error-on-request="error"
								:company-mode="companyMode"
								@abort="abort"
								@send="resume"
							/>
							<template v-if="!newPasswordSet">
								<p class="text-center mt-4 mb-2">Oder anmelden mit:</p>
								<SocialLogin
									:show-linkedin-login="!cordova"
									:companycase="cref || (companyMode && 'b2b') || undefined"
									:usercase="uref || undefined"
									@error="setError"
									@login-result="forwardloginResult"
								/>
							</template>
							<HokButton
								v-show="localStorageAvailable && !cordova"
								class="mt-4"
								fullwidth="always"
								is-text
								:color="companyMode ? 'business' : 'main'"
								font-weight="normal"
								@click="openCookieBanner"
							>
								Cookie Einstellungen
							</HokButton>
						</div>
						<template v-else-if="state === LoginState.signup">
							<HokSignupFormB2B
								v-if="companyMode"
								:privacy="requiredPrivacy"
								:current-login="currentLogin"
								:companycase="cref || (companyMode && 'b2b') || undefined"
								:usercase="uref"
								:error-on-request="error"
								@send="resume"
								@go-back="state = LoginState.login"
							/>
							<HokSignupFormB2C
								v-else
								:privacy="requiredPrivacy"
								:current-login="currentLogin"
								:companycase="cref || (companyMode && 'b2b') || undefined"
								:usercase="uref"
								:error-on-request="error"
								@send="resume"
								@go-back="state = LoginState.login"
							/>
						</template>
					</keep-alive>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { PrivacyType } from '@hokify/shared-components-nuxt3/lib/types/privacyType';
import HeaderOnboarding from '@hokify/shared-components-nuxt3/lib/components/HeaderOnboarding.vue';
import type { IUserPrivacy } from '@hokify/common';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { errorCodes } from '@hokify/shared-components-nuxt3/lib/data/errorCodes';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { SupportedCompanyCase } from '../../types/supported-company-case';
import { SupportedUserCase } from '../../types/supported-user-case';
import { LoginState } from '../../types/enums';
import SocialLogin from './SocialLogin.vue';
import HokSignupFormB2B from './form/HokSignupFormB2B.vue';
import HokSignupFormB2C from './form/HokSignupFormB2C.vue';
import HokLoginForm from './form/HokLoginForm.vue';

import type { LoginActionPayload, OnboardingPayload, IEmptyPayload } from '../../types/login';
import { getRequiredPrivacy } from '../../helpers/get-required-privacy-for-signup';

export default defineComponent({
	name: 'HokLogin',
	components: {
		HeaderOnboarding,
		SocialLogin,
		HokLoginForm,
		HokSignupFormB2B,
		HokSignupFormB2C
	},
	emits: ['login-result'],
	data() {
		const state = undefined as undefined | LoginState;
		const requiredPrivacy: Array<keyof IUserPrivacy> = this.dataObj?.privacy || [];
		const error = null as null | {
			title?: string;
			text?: string;
			code?: string;
		};

		const cordova: string | null = process.env.cordova || null;

		return {
			LoginState,
			localStorageAvailable: lsTest(),
			errorCodes,
			cordova,
			error,
			requiredPrivacy,
			state,
			baseURL: null as string | null,
			counter: 0,
			EventBus
		};
	},
	created() {
		this.state = this.startState;
	},
	computed: {
		teaserHeadline(): string {
			return this.companyMode
				? 'Über hokify erfolgreich neue Mitarbeiter finden!'
				: 'Über hokify neuen Job finden und erfolgreich bewerben!';
		},
		teaserText(): string {
			return this.companyMode
				? 'Jetzt kostenloses Firmenkonto anlegen und in 3 Minuten einen Job ausschreiben.'
				: 'Jetzt kostenlos anmelden und Zugang zu exklusiven Jobs erhalten. Erstelle einmalig dein hokify Profil und bewirb dich mit wenigen Klicks für passende Jobs in deiner Nähe.';
		},
		startState(): LoginState {
			if (this.dataObj?.startingState) {
				return LoginState[this.dataObj.startingState];
			}
			return LoginState.login;
		},
		loginIdentifier(): string {
			return this.isWebsiteCase ? '' : this.$loginStore.loginIdentifier;
		},
		currentLogin(): string {
			return this.dataObj?.email || this.loginUsername || this.loginIdentifier;
		}
	},
	async mounted() {
		await this.$nuxt?.$hokReCaptcha?.initializeRecaptchaWidget();
	},
	methods: {
		setError(error) {
			this.error = error;
		},
		forwardloginResult($event) {
			this.$emit('login-result', $event);
		},
		hasEmptyPayload(obj: { state: LoginState }): IEmptyPayload {
			return obj as IEmptyPayload;
		},
		async resume(payload: OnboardingPayload) {
			this.error = null;
			switch (payload.state) {
				case LoginState.landing:
					if (this.companyMode) {
						this.requiredPrivacy = getRequiredPrivacy(undefined, this.cref || 'b2b');
					} else {
						this.requiredPrivacy = getRequiredPrivacy(this.uref, undefined);
					}
					this.state = LoginState.signup;

					this.$trackGenericEvent?.('register_started', {
						loginMethod: this.currentLogin?.includes('@') ? 'e-mail' : 'sms'
					});
					break;
				case LoginState.reserved:
				case LoginState.pwdReset:
				case LoginState.login:
					try {
						this.state = LoginState.loading;
						const loginPayload: LoginActionPayload = {
							method: 'local-login',
							parameters: {
								email: payload.data.email,
								password: payload.data.password,
								companycase: this.companyMode
									? SupportedCompanyCase[this.cref || 'b2b']
									: undefined,
								usercase: !this.companyMode && this.uref ? SupportedUserCase[this.uref] : undefined
							}
						};
						const result = await this.$nuxt?.$loginStore?.doLogin(loginPayload);
						if (window.PasswordCredential) {
							try {
								const c = new window.PasswordCredential({
									id: payload.data.email,
									password: payload.data.password
								});
								await navigator.credentials.store(c);
							} catch (err: any) {
								console.error('PasswordCredential failed', err);
							}
						}
						this.$trackGenericEvent?.('login', {
							case: this.cref || this.uref,
							mode:
								result.user?.mode || ((this.cref || this.companyMode) && 'company') || 'jobseeker',
							loginMethod: this.currentLogin?.includes('@') ? 'e-mail' : 'sms'
						});

						const acceptPrivacyRequests: Promise<any>[] = [];
						if (payload.data.privacy_company_digital_content) {
							acceptPrivacyRequests.push(
								this.$nuxt?.$userProfileStore?.acceptPrivacy(PrivacyType.CompanyDigitalContent)
							);
						}
						if (payload.data.privacy_user_digital_content) {
							acceptPrivacyRequests.push(
								this.$nuxt?.$userProfileStore?.acceptPrivacy(PrivacyType.UserDigitalContent)
							);
						}
						if (payload.data.privacy_user_register) {
							acceptPrivacyRequests.push(
								this.$nuxt?.$userProfileStore?.acceptPrivacy(PrivacyType.UserRegister)
							);
						}
						if (payload.data.privacy_company_register) {
							acceptPrivacyRequests.push(
								this.$nuxt?.$userProfileStore?.acceptPrivacy(PrivacyType.CompanyRegister)
							);
						}
						if (acceptPrivacyRequests.length) {
							await Promise.all(acceptPrivacyRequests);
						}
						this.$emit('login-result', { loginMethod: 'login', ...result });
					} catch (err: any) {
						if (err.response?.status >= 400 && err.response?.status < 500) {
							// TODO implement handleBadRequest
							// this.handleBadRequest(err.response.data.code);
							this.error = {
								title: 'Login fehlgeschlagen',
								text: 'Bitte versuche es erneut'
							};
							this.state = LoginState.login;
						} else if (err.response?.status === 503) {
							this.error = {
								title: 'Anmeldung fehlgeschlagen',
								text: 'Bitte versuchen Sie es erneut'
							};
							this.state = LoginState.login;
						} else {
							this.$nuxt.$errorHandler(err);
						}
					}
					break;
				case LoginState.signup:
					try {
						this.state = LoginState.loading;
						const websitePayload = { autoLogin: true, ...payload.data };
						await this.$nuxt?.$hokReCaptcha?.sendWithRecaptchaToken(
							this.$nuxt?.$loginStore?.doSignUp,
							this.isWebsiteCase ? websitePayload : payload.data,
							'signup'
						);
						this.$trackGenericEvent?.('register_completed', {
							case: this.cref || this.uref,
							mode: ((this.cref || this.companyMode) && 'company') || 'jobseeker',
							loginMethod: this.currentLogin?.includes('@') ? 'e-mail' : 'sms'
						});
						this.$loginStore.setLoginIdentifier(payload.data.email);

						return this.$emit('login-result', {
							loginMethod: 'signup',
							newUser: true
						});
					} catch (err: any) {
						if (err.response?.status === 503) {
							if (this.isWebsiteCase) {
								this.error = {
									title: 'Registrierung fehlgeschlagen',
									text: 'Bitte versuchen Sie es erneut'
								};
								this.state = LoginState.signup;
							} else {
								this.$loginStore.setLoginIdentifier(payload.data.email);
								this.$loginStore.setPwdServiceUnavailable(true);
								this.$router.push(`/pwa/${this.companyMode ? 'company/' : ''}password?signUp=true`);
							}
						} else {
							this.$nuxt.$errorHandler(err);
						}
					}
					break;
				default:
					console.error(`unsupported resume state ${this.state}`);
					break;
			}
		},
		async abort() {
			this.error = null;
			switch (this.state) {
				case LoginState.login:
					this.state = LoginState.loading;
					try {
						this.state = LoginState.pwdReset;
						this.$trackGenericEvent?.('forgot_password', {});
						if (this.isWebsiteCase) {
							window.location.href = `/pwa/${this.companyMode ? 'company/' : ''}password?forgotPwd=true`;
						} else {
							this.$router.push(
								`/pwa/${this.companyMode ? 'company/' : ''}password?forgotPwd=true`
							);
						}
					} catch (err: any) {
						this.error = {
							text: err
						};
						this.$nuxt.$errorHandler(err);
					}
					break;
				case LoginState.signup:
					window.location.href = this.companyMode ? '/app/company' : '/app-login';
					break;
				default:
					console.error(`unsupported abort state ${this.state}`);
					break;
			}
		},
		switchEnv() {
			// TODO https://app.asana.com/0/1207203365740563/1208269806793009/f
			console.log('click more often, counter is', this.counter);

			// do nothing, until user clicked 10 times
			if (this.counter <= 10) {
				this.counter += 1;
				return;
			}

			if (!process.env.cordova) {
				// eslint-disable-next-line no-alert
				alert('switching environment is not supported in web');
				return;
			}

			const pwd = prompt('switch-environment password?', '');
			// eslint-disable-next-line no-restricted-globals,no-alert
			if (pwd === 'hyhokify' && confirm(`really switch environment? (current: ${this.baseURL})`)) {
				if (this.baseURL === 'https://test.hokify.com') {
					// switch to prod
					// eslint-disable-next-line no-alert
					alert('switch to prod');
					this.baseURL = 'https://hokify.com';
					localStorage.setItem('cordovaEnv', 'production');
				} else {
					// switch to test
					// eslint-disable-next-line no-alert
					alert('switch to test');
					this.baseURL = 'https://test.hokify.com';
					localStorage.setItem('cordovaEnv', 'testing');
				}

				this.$nuxt.$config.public.API_HOST_BROWSER = this.baseURL;
				this.$nuxt.$config.public.API_HOST_SERVER = this.baseURL;
				localStorage.setItem('baseURL', this.baseURL);
			}
			this.counter = 0;
		},
		getError(err: any): { title?: string; text?: string } {
			const errorCode = this.$getErrorCode(err);

			if (!errorCode && typeof err === 'string') {
				return { text: err };
			}

			if (!errorCode) {
				return { text: 'Unbekannter Fehler' };
			}

			return {
				title: this.errorCodes.genericErrors[`${errorCode}_TITLE`] || undefined,
				text: this.errorCodes.genericErrors[errorCode] || errorCode
			};
		},
		openCookieBanner() {
			this.EventBus.$emit('show-cookie-banner');
		}
	},
	props: {
		loginUsername: { type: String, default: '' },
		uref: { type: String as PropType<SupportedUserCase>, default: () => {} },
		cref: { type: String, default: () => {} },
		standalone: { type: Boolean, default: true },
		companyMode: { type: Boolean, default: false },
		showHeader: { type: Boolean, default: true },
		dataObj: { type: Object, default: () => {} },
		newPasswordSet: { type: Boolean, default: false },
		isWebsiteCase: { type: Boolean, default: false }
	}
});
</script>
<style scoped lang="scss">
// TODO rewrite with Tailwind

/* eslint-disable vue-scoped-css/no-unused-selector */
.hok-login {
	.max-w-400px {
		max-width: 400px;
	}
	.logo {
		height: 4rem;
		width: auto;
		display: block;
		margin: 2rem auto;
	}
	.controls {
		a {
			color: inherit;
		}
		.back {
			display: inline-block;
			margin: 0.5rem 0;
			color: white;
		}
		.footer-desc {
			color: $color-text;
			width: 100%;
			display: block;
			line-height: 1rem;
			text-align: center;
			margin-top: 1rem;
		}
		.descline {
			font-size: $font-base;
			margin-left: 1rem;
			svg {
				fill: $color-text;
				height: 14px;
				margin-top: 6px;
				position: absolute;
				margin-left: -25px;
			}
		}
	}

	.intl-phone-input {
		display: block;
		margin: 0 auto;
	}
	.center {
		margin-top: 2rem;
		color: inherit;
		text-align: center;
		h2,
		h3,
		h4 {
			color: inherit;
			margin: 0;
			padding: 0;
			border: none;
		}
		a {
			display: inline-block;
			color: inherit;
		}
	}
	p.smallest {
		a {
			color: #0fb1af;
		}
	}
}
</style>
