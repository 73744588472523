<template>
	<div class="cv-general">
		<div
			v-if="
				!displayFields ||
				displayFields.indexOf('generaldata') !== -1 ||
				displayFields.indexOf('gender') !== -1
			"
			:class="{ 'w-full': styling === 'billing' }"
		>
			<p class="mb-2">Anrede:</p>
			<HokRadioGroup v-model="genderModel" class="flex">
				<HokRadio id="male" name="gender" value="male" class="mr-5"> Herr </HokRadio>
				<HokRadio id="female" name="gender" value="female" class="mr-5"> Frau </HokRadio>
				<HokRadio id="neutral" name="gender" value="neutral"> keine Angabe </HokRadio>
			</HokRadioGroup>
		</div>

		<div :class="{ 'md:flex': styling === 'billing' }">
			<div
				v-if="!displayFields || displayFields.indexOf('firstname') !== -1"
				:class="{ 'w-full md:w-1/2 md:pr-3': styling === 'billing' }"
			>
				<HokInput
					id="cv-general-firstname"
					v-model="userGeneral.firstName"
					:required="styling === 'billing'"
					:min-length="styling === 'billing' ? 2 : undefined"
					:autofocus="autofocus === 'firstname'"
					:question="`${
						styling === 'billing' ? 'Wie lautet Ihr Vorname?' : 'Wie lautet dein Vorname?'
					}`"
					@input="userGeneralUpdate.firstName = userGeneral.firstName"
				>
					Vorname
				</HokInput>
			</div>
			<div
				v-if="!displayFields || displayFields.indexOf('lastname') !== -1"
				:class="{ 'w-full md:w-1/2 md:pr-3': styling === 'billing' }"
			>
				<HokInput
					id="cv-general-lastname"
					v-model="userGeneral.lastName"
					:required="styling === 'billing'"
					:min-length="styling === 'billing' ? 2 : undefined"
					:autofocus="autofocus === 'lastname'"
					:question="`${
						styling === 'billing' ? 'Wie lautet Ihr Familienname?' : 'Wie lautet dein Familienname?'
					}`"
					@input="userGeneralUpdate.lastName = userGeneral.lastName"
				>
					Nachname
				</HokInput>
			</div>
		</div>

		<div :class="[{ 'md:flex': styling === 'billing' }]">
			<div
				v-if="
					!displayFields ||
					displayFields.indexOf('generaldata') !== -1 ||
					displayFields.indexOf('phone') !== -1
				"
				:class="[{ 'w-full md:w-1/2 md:pr-3': styling === 'billing' }]"
				class="relative"
			>
				<EmailOrPhone
					id="cv-general-phone"
					v-model="userGeneral.phone"
					:phone-only="true"
					placeholder="Telefonnummer"
					:preferred-countries="['at', 'de', 'ch']"
					:autofocus="autofocus === 'phone'"
					:required="styling === 'billing'"
					:valid-characters-only="true"
					:question="`${
						styling === 'billing'
							? 'Unter welcher Telefonnummer sind Sie erreichbar?'
							: 'Unter welcher Telefonnummer bist du erreichbar?'
					}`"
				>
					Telefonnummer
				</EmailOrPhone>
				<div
					v-if="userGeneral.phone && verificationMode"
					class="absolute top-7 bg-color-white z-10"
					:class="$isMobile.any ? 'right-3' : styling === 'billing' ? 'right-6' : 'right-[92px]'"
				>
					<ToolTip
						v-if="storeUser && !storeUser.accountVerifiedPhone"
						icon="icon:alert"
						color="purple"
						:size="5"
						:styling="styling === 'billing' ? 'company' : 'user'"
						vertical-align="align-bottom"
						:text="notVerifiedText"
						custom-handler
						@click="showVerifyModal('phone')"
					/>
				</div>
			</div>
			<div
				v-if="
					!displayFields ||
					displayFields.indexOf('generaldata') !== -1 ||
					displayFields.indexOf('email') !== -1
				"
				:class="[{ 'w-full md:w-1/2 md:pr-3': styling === 'billing' }]"
				class="relative"
			>
				<HokInput
					id="cv-general-email"
					v-model="userGeneral.email"
					:required="styling === 'billing'"
					:autofocus="autofocus === 'email'"
					:min-length="styling === 'billing' ? 2 : undefined"
					type="email"
					:question="`${
						styling === 'billing'
							? 'Wie lautet Ihre E-Mail-Adresse?'
							: 'Wie lautet deine E-Mail-Adresse?'
					}`"
					@input="userGeneralUpdate.email = userGeneral.email"
				>
					E-Mail-Adresse
				</HokInput>
				<div
					v-if="userGeneral.email && verificationMode"
					class="absolute top-7 bg-color-white z-10"
					:class="$isMobile.any ? 'right-3' : styling === 'billing' ? 'right-6' : 'right-[92px]'"
				>
					<ToolTip
						v-if="storeUser && !storeUser.accountVerifiedEmail"
						icon="icon:alert"
						color="purple"
						:size="5"
						:styling="styling === 'billing' ? 'company' : 'user'"
						vertical-align="align-bottom"
						text="Deine E-Mail-Adresse ist noch nicht verifiziert. Verifiziere sie, wenn du sie zum
								Anmelden verwenden willst."
						custom-handler
						@click="showVerifyModal('email')"
					/>
				</div>
				<p v-if="styling === 'billing'" class="text-xs opacity-50 -mt-3 ml-1 mb-0">
					(Empfängeradresse für Bewerbungen)
				</p>
			</div>
		</div>

		<div
			v-if="
				!displayFields ||
				displayFields.indexOf('generaldata') !== -1 ||
				displayFields.indexOf('residence') !== -1
			"
		>
			<HokInput
				id="cv-general-street"
				v-model="userGeneral.street"
				:autofocus="autofocus === 'street'"
				question="In welcher Straße wohnst du?"
				@input="updateAddress('street', userGeneral.street)"
			>
				Adresse
			</HokInput>

			<HokInput
				id="cv-general-zipcode"
				v-model="userGeneral.zipcode"
				:autofocus="autofocus === 'zipcode'"
				question="Wie lautet die Postleitzahl deines Wohnortes?"
				@input="updateAddress('code', userGeneral.zipcode)"
			>
				PLZ
			</HokInput>

			<Autocomplete
				id="cv-general-city"
				v-model="userGeneral.city"
				:autofocus="autofocus === 'city'"
				:value-promise="ontologyCitySuggestion"
				question="Wie heißt dein Wohnort (Stadt)?"
				@input="updateAddress('city', $event)"
			>
				Wohnort
			</Autocomplete>
			<Autocomplete
				id="cv-general-country"
				v-model="userGeneral.country"
				:autofocus="autofocus === 'country'"
				:min-length="0"
				:value-promise="countryListSuggestion"
				value-label="name"
				:must-select="true"
				value-id="country"
				question="In welchem Land wohnst du?"
				@input="updateAddress('country', userGeneral.country)"
			>
				Land
			</Autocomplete>
		</div>

		<!-- SPECIAL CASE FOR B2B APP WHERE WE ONLY WANT COUNTRY SELECTION -->
		<div
			v-if="
				!displayFields ||
				displayFields.indexOf('generaldata') !== -1 ||
				displayFields.indexOf('country') !== -1
			"
		>
			<Autocomplete
				id="cv-general-country"
				v-model="userGeneral.country"
				:autofocus="autofocus === 'country'"
				:min-length="0"
				:value-promise="countryListSuggestion"
				value-label="name"
				value-id="countryCode"
				question="In welchem Land wohnst du?"
				:must-select="true"
				class="w-full md:w-1/2 md:pr-3"
				@input="(value, event, params) => updateAddress('countryCode', params.id)"
			>
				Land
			</Autocomplete>
		</div>

		<div
			v-if="
				!displayFields ||
				displayFields.indexOf('generaldata') !== -1 ||
				displayFields.indexOf('nationality') !== -1
			"
		>
			<Autocomplete
				id="cv-general-nationality"
				v-model="userGeneral.nationality"
				:autofocus="autofocus === 'nationality'"
				:min-length="0"
				:value-promise="countryListSuggestion"
				value-label="name"
				value-id="_id"
				question="Was ist deine Nationalität?"
				@input="updateNationality"
			>
				Nationalität
			</Autocomplete>
		</div>

		<div
			v-if="
				!displayFields ||
				displayFields.indexOf('generaldata') !== -1 ||
				displayFields.indexOf('birthday') !== -1
			"
		>
			<HokDatePicker
				id="cv-general-birthday"
				ref="cvGeneralBirthday"
				:value="userGeneral.birthday"
				:language="$nuxt?.$userRootStore?.locale"
				:open-date="new Date('1990-01-01')"
				:disabled-from="new Date()"
				:typeable="true"
				initial-view="year"
				format="dd.MM.yyyy"
				minimum-view="day"
				@input="userGeneralUpdate.birthday = createDateAsUTC($event)"
				@opened="focusBirthday"
			>
				Geburtsdatum
			</HokDatePicker>
		</div>

		<!--user not verified yet - confirm via code-->
		<!-- nearly same modal is used in JobPublishAction.vue-->
		<!-- nearly same modal is used in MatchOverview.vue-->
		<HokModal
			width="390px"
			:adaptive="true"
			:name="`user-not-verified-${myId}`"
			@opened="resendVerification(false)"
		>
			<h3 class="text-center">
				{{ contactType === 'email' ? 'E-Mail-Adresse' : 'Telefonnummer' }} bestätigen
			</h3>
			<p class="mb-2">
				Wir haben einen 4 stelligen Code an
				<strong v-if="contactType === 'email'" class="text-center block mb-2 sm:mt-2 break-all">{{
					storeUser && storeUser.general && storeUser.general.email
				}}</strong>
				<strong v-else class="text-center block mb-2 sm:mt-2 break-all">{{
					storeUser && storeUser.general && storeUser.general.phone
				}}</strong>
				gesendet. Bitte gib den Code ein, um deine
				{{ contactType === 'email' ? 'E-Mail-Adresse' : 'Telefonnummer' }} zu bestätigen.
			</p>
			<form class="text-center" @submit="verifyAccount($event)">
				<ErrorBox v-if="showInvalidCode"> Ungültiger Code! </ErrorBox>
				<HokInput
					id="verifycode"
					v-model="verifyCode"
					pattern="[0-9]*"
					styling="text-center"
					placeholder="Code hier eingeben"
					inputmode="numeric"
					name="code"
					:max-length="6"
					:min-length="4"
					:autofocus="true"
					class="mb-2"
					@change="codeChanged"
					@click="codeChanged"
				/>
				<HokButton id="submitverifycode" fullwidth="always" submit color="main"
					>Bestätigen</HokButton
				>
				<HokButton
					v-if="!resentVerification"
					id="resendverifycode"
					class="my-4"
					is-text
					color="business"
					@click="resendVerification(true)"
				>
					Code erneut zusenden
				</HokButton>
			</form>
		</HokModal>

		<HokModal width="390px" :adaptive="true" name="dataRequired">
			<h3 class="text-center">Speichern nicht möglich</h3>
			<p>{{ dataRequiredMessage }}</p>
			<HokButton fullwidth="always" color="business" @click="$modal.hide('dataRequired')">
				Verstanden
			</HokButton>
		</HokModal>
		<HokModal
			click-to-close
			:adaptive="true"
			name="save-error"
			:width="$isMobile.any ? '95%' : '350px'"
		>
			<div class="upload-section">
				<h3>Fehler beim Speichern</h3>
				<p>{{ saveError }}</p>
				<HokButton fullwidth="always" color="main" @click="$modal.hide('save-error')">
					Ok
				</HokButton>
			</div>
		</HokModal>
	</div>
</template>

<script lang="ts">
import type {
	IAPILoginUser,
	IAPIUser,
	IAPILocation,
	Gender,
	APITypeObjectId,
	APIObjectType
} from '@hokify/common';
import { defineComponent } from 'vue';
import type { PropType, ComponentPublicInstance } from 'vue';
import ErrorBox from './ErrorBox.vue';
import ToolTip from './ToolTip.vue';
import HokDatePicker from './HokDatePicker.vue';
import Autocomplete from './Autocomplete.vue';
import HokInput from './HokInput.vue';
import HokRadioGroup from './HokRadioGroup.vue';
import HokRadio from './HokRadio.vue';
import { format } from '../helpers/datehelpers/format';
import EmailOrPhone from './EmailOrPhone.vue';
import { calcAge } from '../helpers/datehelpers/calculate-age';
import { errorCodes } from '../data/errorCodes';

function hasVueElement(vue: any): vue is ComponentPublicInstance {
	return vue && !!vue.$el;
}

export default defineComponent({
	components: {
		EmailOrPhone,
		HokInput,
		Autocomplete,
		HokDatePicker,
		HokRadioGroup,
		HokRadio,
		ErrorBox,
		ToolTip
	},
	data() {
		const userGeneralUpdate: {
			firstName?: string;
			lastName?: string;
			email?: string;
			phone?: string;
			birthday?: Date;
			gender?: Gender;
			interests?: string;
			slogan?: string;
			address?: Partial<IAPILocation>;
			nationality?: APITypeObjectId<APIObjectType.Country>;
		} = {};

		const userGeneral: {
			firstName?: string;
			lastName?: string;
			email?: string;
			phone?: string;
			birthday?: Date;
			gender?: string;
			interests?: string;
			slogan?: string;
			address?: Partial<IAPILocation>;
			nationality?: string;
			zipcode?: string;
			city?: string;
			street?: string;
			country?: string;
		} = {};

		const saveError = '';

		return {
			format,
			notVerifiedText:
				'Deine Telefonnummer ist noch nicht verifiziert. Verifiziere sie, wenn du sie zum Anmelden verwenden willst.',
			contactType: 'email',
			myId: Math.random(),
			myActiveId: 'contactData',
			isSaveAlreadyCalled: false,
			verifyCode: '',
			showInvalidCode: false,
			resentVerification: false,
			loading: false,
			dataRequiredMessage: '',
			calcAge,
			userGeneralUpdate,
			userGeneral,
			saveError,
			errorCodes
		};
	},
	computed: {
		storeUser(): IAPILoginUser | undefined {
			return this.$nuxt?.$userProfileStore?.obj;
		},
		genderModel: {
			get() {
				return this.userGeneral.gender;
			},
			set(value) {
				this.updateGender(value);
			}
		}
	},
	created() {
		if (this.storeUser) {
			this.userGeneral = this.computeUserGeneral(this.storeUser);
		}
	},
	mounted() {
		this.$hokReCaptcha.initializeRecaptchaWidget();
	},
	beforeUnmount() {
		if (!this.isSaveAlreadyCalled) {
			this.save();
		}
	},
	methods: {
		async resendVerification(resend = false) {
			const url = 'profile';

			try {
				if (resend) {
					this.resentVerification = true;

					await this.$hokReCaptcha.sendWithRecaptchaToken(
						this.sendVerificationCode,
						{
							entryUrl: url,
							verificationType: true
						},
						'verificationCode'
					);

					this.$snack.success({
						text: 'Code erneut versendet!'
					});
				} else {
					this.resentVerification = false;

					await this.$hokReCaptcha.sendWithRecaptchaToken(
						this.sendVerificationCode,
						{
							entryUrl: url,
							verificationType: this.contactType
						},
						'verificationCode'
					);
				}
			} catch (err: any) {
				if (err.code === 'ALREADY_VERIFIED') {
					this.$snack.show({
						text: 'Dein Account ist bereits verifiziert.'
					});
				} else {
					this.$nuxt.$errorHandler(err);
				}
			}
		},
		async verifyAccount(e: Event) {
			if (e) {
				e.preventDefault();
			}

			if (!this.verifyCode) {
				this.showInvalidCode = true;
				return;
			}

			try {
				this.loading = true;
				await this.verify(this.verifyCode);
				this.showInvalidCode = false;
				this.verifyCode = '';
				this.$modal.hide(`user-not-verified-${this.myId}`);
				this.$snack.success({
					text: 'Du hast dich erfolgreich verifiziert.'
				});
				this.$trackGenericEvent?.('user_verified', {});
			} catch (err: any) {
				this.loading = false;
				this.showInvalidCode = true;
			}
		},
		codeChanged() {
			this.showInvalidCode = false;
		},
		updateNationality(value, _v, a) {
			this.userGeneralUpdate.nationality = (a && a.id) || undefined;

			this.userGeneral.nationality = value;
		},
		updateAddress(key: string, value: string | undefined) {
			if (!this.userGeneralUpdate.address) {
				this.userGeneralUpdate.address = {};
			}

			const adr = this.userGeneralUpdate.address;

			adr[key] = value;
		},
		createDateAsUTC(date: Date): Date {
			return new Date(
				Date.UTC(
					date.getFullYear(),
					date.getMonth(),
					date.getDate(),
					date.getHours(),
					date.getMinutes(),
					date.getSeconds()
				)
			);
		},
		updateGender(value: string) {
			// set value to null, if value was 'neutral' due to radio only works with string values
			if (value === 'neutral') {
				this.userGeneralUpdate.gender = null as any;
			} else {
				this.userGeneralUpdate.gender = value as Gender;
			}
			this.userGeneral.gender = value as Gender;
		},
		computeUserGeneral(internalUser: IAPILoginUser | IAPIUser) {
			return {
				firstName: internalUser?.general?.firstName,
				lastName: internalUser?.general?.lastName,
				phone: internalUser?.general?.phone,
				email: internalUser?.general?.email,
				street: internalUser?.general?.address?.street,
				zipcode: internalUser?.general?.address?.code,
				city: internalUser?.general?.address?.city,
				country: internalUser?.general?.address?.country,
				birthday: internalUser?.general?.birthday,
				gender: internalUser?.general?.gender?.toString() || 'neutral',
				nationality: internalUser?.general?.nationality
			};
		},
		async save() {
			this.dataRequiredMessage = '';
			// for B2B users don't allow deletion of firstname and lastname
			if (
				this.styling === 'billing' &&
				(!this.userGeneral.firstName ||
					this.userGeneral.firstName.trim().length < 2 ||
					!this.userGeneral.lastName ||
					this.userGeneral.lastName.trim().length < 2)
			) {
				this.dataRequiredMessage =
					'Für die Nutzung von hokify sind sowohl Ihr Vorname als auch Nachname nötig. Bitte vervollständigen Sie diese.';
				this.$modal.show('dataRequired');
				return false;
			}
			// it's not allowed to delete email and phone
			if (
				(!this.userGeneral.phone || this.userGeneral.phone.trim().length < 3) &&
				(!this.userGeneral.email || this.userGeneral.email.trim().length < 3)
			) {
				this.dataRequiredMessage =
					'Für die Nutzung von hokify ist entweder deine E-Mail Adresse oder deine Telefonnummer notwendig, damit dich Firmen, bei denen du dich bewirbst, auch erreichen können.';
				this.$modal.show('dataRequired');
				return false;
			}

			try {
				// no need to call the update function, if there are no changes
				if (Object.keys(this.userGeneralUpdate).length === 0) {
					return true;
				}

				const userGeneralUpdate = {
					...this.userGeneralUpdate,
					checkForWorkPermit: this.checkForWorkPermit
				};

				this.userGeneralUpdate = {};
				try {
					const update = await this.updateGeneral(userGeneralUpdate);
					if (update?.userUpdate) {
						if (update.userUpdate['general.email'] && !update.userUpdate.accountVerifiedEmail) {
							this.contactType = 'email';
							this.$modal.show(`user-not-verified-${this.myId}`);
						} else if (
							update.userUpdate['general.phone'] &&
							!update.userUpdate.accountVerifiedPhone
						) {
							this.contactType = 'phone';
							this.$modal.show(`user-not-verified-${this.myId}`);
						} else {
							return true;
						}
					} else {
						return true;
					}
				} catch (err: any) {
					// on error reset update obj
					this.userGeneralUpdate = userGeneralUpdate;
					this.$nuxt.$errorHandler(err);
				}
			} catch (err: any) {
				this.cvGeneralErrorHandler(err);
				return false;
			}
			return false;
		},
		showVerifyModal(type: string) {
			this.contactType = type;
			this.$modal.show(`user-not-verified-${this.myId}`);
		},
		cvGeneralErrorHandler(err: any) {
			if (err?.response?.data?.code) {
				this.$modal.show('save-error');
				this.saveError =
					this.errorCodes.genericErrors[err.response.data.code] || err.response.data.code || err;
			} else {
				this.$nuxt.$errorHandler(err);
			}
		},
		focusBirthday() {
			this.$nextTick(() => {
				const element = this.$refs.cvGeneralBirthday;
				if (hasVueElement(element)) {
					element.$el.scrollIntoView({ behavior: 'smooth', block: 'start' });
				}
			});
		},
		updateGeneral(update) {
			return this.$nuxt?.$userProfileGeneralStore?.updateGeneral(update);
		},
		ontologyCitySuggestion(term) {
			return this.$nuxt?.$valuesStore?.ontologyCitySuggestion(term);
		},
		countryListSuggestion(term) {
			return this.$nuxt?.$valuesStore?.countryListSuggestion(term);
		},
		verify(verifyCode) {
			return this.$nuxt?.$loginStore?.verify(verifyCode);
		},
		sendVerificationCode({
			entryUrl,
			recaptchaToken,
			recaptchaVersion,
			verificationType
		}: {
			entryUrl: string;
			recaptchaToken: string;
			recaptchaVersion: 'v2' | 'v3';
			verificationType?: string | true;
		}): Promise<{ email?: boolean; sms?: boolean }> | undefined {
			const payload = {
				entryUrl,
				recaptchaToken,
				recaptchaVersion,
				verificationType
			};

			return this.$nuxt?.$userProfileStore?.sendVerificationCode(payload);
		}
	},
	props: {
		displayFields: { type: Array, required: true },
		verificationMode: { type: Boolean, default: false },
		autofocus: { type: String, default: '' },
		styling: { type: String, default: '' },
		user: { type: Object as PropType<IAPILoginUser | IAPIUser>, required: false },
		profileOrMatchOverview: { type: Boolean, default: false },
		checkForWorkPermit: { type: Boolean, default: false }
	},
	watch: {
		userGeneral: {
			handler() {
				if (this.userGeneral.gender === undefined) {
					this.userGeneral.gender = 'neutral';
				}
			}
		},
		'userGeneral.phone': {
			handler(value) {
				this.userGeneralUpdate.phone = value;
			}
		}
	}
});
</script>
