<template>
	<div v-if="showCookieBanner">
		<template v-if="$isMobile.phone">
			<div class="fixed inset-0 w-full h-screen z-[200] bg-[#000000]/20" @click="preventClick" />
			<div class="fixed bottom-0 h-3/4 z-[210] shadow">
				<CookieBanner :stage="stage" :mode="mode" @close="toggle(false)" />
			</div>
		</template>
		<HokModal
			v-else
			name="cookieBannerModal"
			:show-close-button="false"
			height="70%"
			classes="v--modal noPadding"
			z-index="z-[300]"
			@vue:mounted="$modal.show('cookieBannerModal')"
		>
			<CookieBanner :stage="stage" :mode="mode" @close="toggle(false)" />
		</HokModal>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IDeferredPromise, newDeferredPromise } from '../../helpers/promise';
import { lsTest } from '../../helpers/localstorage';
import CookieBanner from './CookieBanner.vue';
import { EventBus } from '../../eventbus';

export default defineComponent({
	name: 'CookieBannerContainer',
	components: { CookieBanner },
	emits: ['show', 'hide'],
	data() {
		const stage = 'overview' as 'overview' | 'config';
		const waitTillUserHasInteractedPromise = undefined as undefined | IDeferredPromise;

		return {
			waitTillUserHasInteractedPromise,
			showCookieBanner: false,
			isOpen: false,
			stage,
			EventBus
		};
	},
	beforeMount() {
		this.EventBus.$on('toggleCookieBanner', this.toggle);
	},
	mounted() {
		// only trigger the cookie banner when page load event has been fired
		this.winLoad(async () => {
			// TODO: nuxt3 add this again and check how to access $route.meta
			//  this.$route.meta?.showCookieBanner !== 'false' &&
			if (this.needsToAcceptCookies()) {
				// wait till the user has interacted with the page
				await this.waitTillUserHasInteracted();
				this.EventBus.$emit('show-cookie-banner');
				this.$emit('show');
			}
		});
	},
	beforeUnmount() {
		this.EventBus.$off('toggleCookieBanner', this.toggle);
	},
	methods: {
		async waitTillUserHasInteracted() {
			if (this.hasInteracted) {
				return;
			}

			if (!this.waitTillUserHasInteractedPromise) {
				this.waitTillUserHasInteractedPromise = newDeferredPromise();
			}
			await this.waitTillUserHasInteractedPromise?.promise;
		},
		winLoad(callback) {
			if (document.readyState === 'complete' || document.readyState === 'interactive') {
				callback();
			} else {
				window.addEventListener('load', callback);
			}
		},
		needsToAcceptCookies(): boolean {
			if (lsTest()) {
				const acceptedCookies = localStorage.getItem('accept-cookies');
				// user has already accepted cookies -> load tracking
				if (acceptedCookies) {
					window.acceptedTracking?.();
				}
				return !acceptedCookies;
			}
			return false; // don't show cookieBanner if it can't be stored anyway
		},
		toggle(open = true, params?: any) {
			if (params) {
				if (params?.stage === 'config') {
					this.stage = 'config';
				}
			}
			if (open) {
				this.showCookieBanner = true;
				this.$emit('show');
			} else {
				this.showCookieBanner = false;
				window.acceptedTracking?.();
				this.$emit('hide');
			}
		},
		preventClick(e) {
			e?.preventDefault();
		},
		watchHasInteracted(newVal) {
			if (newVal && this.waitTillUserHasInteractedPromise) {
				this.waitTillUserHasInteractedPromise.resolve();
			}
		}
	},
	props: {
		hasInteracted: { type: Boolean, required: true },
		mode: {
			type: String,
			required: false,
			validator: (value: string) => ['user', 'company'].includes(value)
		}
	},
	watch: {
		hasInteracted: [
			{
				handler: 'watchHasInteracted'
			}
		]
	}
});
</script>
