<template>
	<div v-if="internalInsidePages.length" class="main relative">
		<!-- inside page content -->
		<div v-for="(insidePage, index) in internalInsidePages" :key="`${insidePage.page}-${index}`">
			<Transition
				mode="out-in"
				:name="
					($isMobile.any &&
						(index === topCardIndex || !insidePage.visible) &&
						(insidePage.config && insidePage.config.mode === 'modal'
							? 'inside-page-' + pageDirection + '-bottom'
							: 'inside-page-' + pageDirection + '-side')) ||
					undefined
				"
				appear
				@after-enter="transitionFinished('enter')"
				@after-leave="transitionFinished('leave')"
			>
				<div
					v-show="index === topCardIndex || index === lastCardIndex"
					:id="insidePage.pageId"
					:key="`insidePage-${index}`"
					ref="insidePage"
					:style="{
						'z-index':
							index +
							10 +
							(insidePage.config && insidePage.config.mode === 'modal'
								? 500
								: 0) /* inside page modals need to be higher than regular modals */
					}"
					:class="[
						insidePage.config && insidePage.config.mode === 'modal'
							? 'inside-modal'
							: 'inside-vertical',
						companyMode && insidePage.config && insidePage.config.mode !== 'modal'
							? 'bg-color-grey-lightest'
							: 'bg-color-white',
						$isMobile.any ? 'h-full' : 'h-[calc(100%-65px)]'
					]"
					class="fixed w-full overflow-y-auto flex flex-col inside-page-scroll-container"
				>
					<!-- modal mode (closeable icon right top) -->
					<div
						v-if="insidePage.config && insidePage.config.mode === 'modal'"
						:class="{ 'border-top border-color-main border-t-4': $isMobile.any }"
					>
						<div class="flex max-w-5xl m-auto">
							<h4 class="flex-1 px-4 mt-4 md:text-center">
								<component
									:is="insidePage.config.pageTitleBefore.component"
									v-bind="insidePage.config.pageTitleBefore.props"
									v-if="insidePage.config.pageTitleBefore"
									v-on="
										insidePage.config &&
										insidePage.config.pageTitleBefore &&
										insidePage.config.pageTitleBefore.on
									"
								/>
								{{ insidePage.config.modalHeading }}
								<component
									:is="insidePage.config.pageTitleAfter.component"
									v-bind="insidePage.config.pageTitleAfter.props"
									v-if="insidePage.config.pageTitleAfter"
									v-on="
										insidePage.config &&
										insidePage.config.pageTitleAfter &&
										insidePage.config.pageTitleAfter.on
									"
								/>
							</h4>
							<div class="close-insidepage-mobile" @click="closeInsidePage">
								<HokIcon name="icon:close-slim" color="text" :size="4" pointer />
							</div>
						</div>
					</div>

					<span
						v-else-if="!showMobileInsidePageHeader(insidePage)"
						:class="{
							'close-insidepage-mobile': $isMobile.any,
							'close-insidepage-desktop': !$isMobile.any
						}"
						class="fixed z-10 cursor-pointer rounded-full bg-color-white flex items-center justify-center md:-mt-1"
						@click="closeInsidePage"
					>
						<HokIcon name="icon:close-slim" color="text" :size="4" pointer />
					</span>

					<!-- title bar with back icon -->
					<div
						v-else
						ref="headerBar"
						:style="
							$isMobile.any &&
							!$isMobile.apple.phone && {
								top: '-' + getInsidePageVars(index, 'scrollOffset') + 'px',
								'border-bottom':
									getInsidePageVars(index, 'scrollDirection') === 'up' &&
									!getInsidePageVars(index, 'onTopInsidePageHeader')
										? '1px solid #d6d6d6'
										: ''
							}
						"
						:class="{ 'mobile-header-bar': $isMobile.any }"
					>
						<!--for top (and tabs), we leave it there-->
						<div class="flex bg-color-white">
							<div class="close-insidepage-mobile" @click="closeInsidePage">
								<HokIcon name="icon:arrow-left" color="text" pointer class="inline" :size="4" />
								<span
									v-if="insidePage.config && insidePage.config.breadcrumb"
									class="inline-block hidden md:inline cursor-pointer text-color-main"
								>
									{{ insidePage.config.breadcrumb }}
								</span>
							</div>
							<h4 v-if="insidePage.config" class="flex-1 text-center self-center pr-8 mb-0">
								<component
									:is="insidePage.config.pageTitleBefore.component"
									v-bind="insidePage.config.pageTitleBefore.props"
									v-if="insidePage.config.pageTitleBefore"
									v-on="
										insidePage.config &&
										insidePage.config.pageTitleBefore &&
										insidePage.config.pageTitleBefore.on
									"
								/>
								{{ insidePage.config.pageTitle }}
								<component
									:is="insidePage.config.pageTitleAfter.component"
									v-bind="insidePage.config.pageTitleAfter.props"
									v-if="insidePage.config.pageTitleAfter"
									v-on="
										insidePage.config &&
										insidePage.config.pageTitleAfter &&
										insidePage.config.pageTitleAfter.on
									"
								/>
							</h4>
						</div>
					</div>
					<InsidePage
						:page-title="insidePage && insidePage.config && insidePage.config.pageTitle"
						:current-index="index + 1"
						:page-titles="pageTitles"
					>
						<component
							:is="insidePage.preparedComponent.is"
							v-if="insidePage.preparedComponent"
							key="`insidePageComponent-${insidePage.page}-${index}`"
							:index="index"
							v-bind="
								insidePage && insidePage.preparedComponent && insidePage.preparedComponent.props
							"
							:slots="
								insidePage && insidePage.preparedComponent && insidePage.preparedComponent.slots
							"
							:scoped-slots="
								insidePage &&
								insidePage.preparedComponent &&
								insidePage.preparedComponent.scopedSlots
							"
							:class="{
								'insidepage-mobile': $isMobile.any,
								'pt-12': $isMobile.any && insidePage.config && insidePage.config.pageTitle,
								'flex-1': insidePage.config && insidePage.config.mode === 'modal' /* for modals */,
								'overflow-y-auto': insidePage.config && insidePage.config.mode === 'modal' /* */
							}"
							:style="{
								'padding-top':
									$isMobile.any &&
									!$isMobile.apple.phone &&
									insidePage.config &&
									insidePage.config.pageTitle &&
									48 - getInsidePageVars(index, 'scrollOffset') + 'px'
							}"
							class="insidepage flex flex-col"
						/>
					</InsidePage>
				</div>
			</Transition>
		</div>
	</div>
</template>

<script lang="ts">
// eslint-disable-next-line import/no-extraneous-dependencies
import { defineComponent, nextTick } from 'vue';
import { delay } from '../../helpers/promise';
import { insidePages } from './insidePageService';
import InsidePage from './InsidePage.vue';
import HokIcon from '../../components/HokIcon.vue';
import { EventBus } from '../../eventbus';

const headerBarHeight = 48;

function isElement(obj: Element): obj is Element {
	return obj && obj instanceof Element;
}

export default defineComponent({
	name: 'InsidePageRoot',
	components: { HokIcon, InsidePage },
	emits: ['compute-css-headers'],
	data() {
		const lastCardIndex = undefined as undefined | number;
		const ensureLastScroll: any = undefined;
		const insidePageVars: Record<number, any> = {};
		const rootTitle = '';
		return {
			insidePageVars,
			ensureLastScroll,
			lastCardIndex,
			EventBus,
			rootTitle
		};
	},
	computed: {
		getInsidePageVars() {
			return (index, key) => {
				if (index === 'current') {
					index = this.internalInsidePages.length - 1;
				}

				this.initInsidePageVars(index);

				return this.insidePageVars[index][key];
			};
		},
		internalInsidePages() {
			return insidePages.pages;
		},
		pageDirection() {
			return insidePages.navDirection;
		},
		topCardIndex() {
			let index = -1;
			this.internalInsidePages?.some(ip => {
				if (!ip.visible) {
					return true;
				}
				index += 1;
				return false;
			});
			return index;
		},
		pageTitles() {
			const pageTitles: string[] = [this.rootTitle];
			this.internalInsidePages.forEach(page => {
				if (page.config?.pageTitle) {
					pageTitles.push(page.config?.pageTitle);
				} else {
					pageTitles.push('');
				}
			});
			return pageTitles;
		}
	},
	beforeMount() {
		this.EventBus.$on('inside-page-closed', this.insidePageClosedEvent);

		this.EventBus.$on('scroll-to-top', this.myScrollToTop);
		this.rootTitle = document.title;
	},
	mounted() {
		if (this.$isMobile.any) {
			window.addEventListener('scroll', this.scrollEvent.bind(this), true);
			window.addEventListener('touchstart', this.touchStart.bind(this), true);
			window.addEventListener('touchend', this.touchEnd.bind(this), true);
		}
	},
	methods: {
		insidePageClosedEvent() {
			nextTick(() => {
				this.$emit('compute-css-headers');
			});
		},
		async myScrollToTop() {
			this.$emit('compute-css-headers');

			if (
				this.$refs.insidePage &&
				Array.isArray(this.$refs.insidePage) &&
				this.$refs.insidePage.length > 0
			) {
				const currentInsidePageIndex = this.$refs.insidePage.length - 1;
				const currentInsidePage = this.$refs.insidePage[currentInsidePageIndex];

				if (isElement(currentInsidePage)) {
					// ensure everyhting is visible, ensure animation is finished (400ms)
					await Promise.race([
						// eslint-disable-next-line no-promise-executor-return
						new Promise(resolve => this.EventBus.$on('transition-finished-enter', resolve)),
						delay(1000)
					]);
					currentInsidePage.scrollIntoView();
				}

				this.insidePageScrollToTop(currentInsidePageIndex);
			}
		},
		beforeUnmount() {
			this.EventBus.$off('inside-page-closed', this.insidePageClosedEvent);
			this.EventBus.$off('scroll-to-top', this.myScrollToTop);
			if (this.$isMobile.any) {
				window.removeEventListener('scroll', this.scrollEvent.bind(this));
				window.removeEventListener('touchstart', this.touchStart.bind(this));
				window.removeEventListener('touchend', this.touchEnd.bind(this));
			}
		},
		initInsidePageVars(index) {
			if (!this.insidePageVars[index]) {
				// init with defaults
				this.insidePageVars[index] = {
					lastScrollPos: 0,
					lastTouchPos: 0,
					onTopInsidePageHeader: true,
					scrollDirection: 'down',
					lastScrollUpOffSet: headerBarHeight,
					scrollOffset: 0
				};
			}
		},
		showMobileInsidePageHeader(insidePage) {
			let currentInsidePage = insidePage;
			if (!currentInsidePage) {
				currentInsidePage = this.internalInsidePages[this.internalInsidePages.length - 1];
			}

			return currentInsidePage?.config?.pageTitle;
		},
		setInsidePageVar(insidePageIndex, key, value) {
			if (insidePageIndex === 'current') {
				insidePageIndex = this.internalInsidePages.length - 1;
			}

			this.initInsidePageVars(insidePageIndex);

			this.insidePageVars[insidePageIndex][key] = value;
		},
		insidePageScrollToTop(insidePageIndex) {
			// reset header bar
			this.setInsidePageVar(insidePageIndex, 'lastScrollPos', 0);
			this.setInsidePageVar(insidePageIndex, 'scrollOffset', 0);

			this.setInsidePageVar(insidePageIndex, 'scrollDirection', 'down');
			this.setInsidePageVar(insidePageIndex, 'onTopInsidePageHeader', true);
		},
		touchStart($event) {
			this.setInsidePageVar(
				'current',
				'lastTouchPos',
				($event &&
					$event.changedTouches &&
					$event.changedTouches[0] &&
					$event.changedTouches[0].pageY) ||
					0
			);
		},
		touchEnd($event) {
			const te = $event && $event.changedTouches && $event.changedTouches[0].pageY;
			if (
				this.getInsidePageVars('current', 'lastTouchPos') < te - 15 &&
				this.getInsidePageVars('current', 'lastTouchPos')
			) {
				this.setInsidePageVar('current', 'lastScrollPos', 0);
				this.setInsidePageVar('current', 'scrollOffset', 0);
			}
		},
		scrollEvent($event, lastScroll?: any) {
			/* 2 use cases:
												1. regular one (page and header are scrolled) -> WORKS
												2.a has tabs (the tab component scrolls) -> WORKS
												2.b has some other content in the page (e.g. footer) and the scorll content is inside (similar to tab) -> TODO (detect if page has scroll components in it.. shuld be doable ;))
											 */
			if (!this.$refs.headerBar) {
				return;
			}

			// header bar 48px
			let newScrollDirection;

			const scrollTop = $event && $event.target && $event.target.scrollTop;

			if (scrollTop > $event.target.scrollHeight - $event.target.clientHeight - headerBarHeight) {
				return;
			}

			// do some chill mode
			// < scrollPos

			let scrollPos;
			const lastScrollPos = this.getInsidePageVars('current', 'lastScrollPos');
			if (lastScrollPos < scrollTop) {
				// scrolling down
				scrollPos = scrollTop;
				newScrollDirection = 'down';
			} else if (lastScrollPos > scrollTop) {
				// scrolling up
				scrollPos = scrollTop;

				newScrollDirection = 'up';
			} else {
				scrollPos = scrollTop;
			}

			if (
				newScrollDirection === 'up' &&
				this.getInsidePageVars('current', 'scrollOffset') === headerBarHeight
			) {
				// reset last scroll position if header bar is not visible anymore and we scroll up
				this.setInsidePageVar('current', 'lastScrollUpOffSet', scrollPos - (headerBarHeight - 1));
				this.setInsidePageVar('current', 'scrollOffset', headerBarHeight - 1);
			} else if (
				newScrollDirection === 'down' &&
				this.getInsidePageVars('current', 'scrollOffset') === 0
			) {
				// reset last scroll position if header bar is visible and we scroll down
				this.setInsidePageVar('current', 'lastScrollUpOffSet', scrollPos);
				this.setInsidePageVar('current', 'scrollOffset', 1);
			} else {
				const offset = Math.min(
					Math.max(scrollPos - this.getInsidePageVars('current', 'lastScrollUpOffSet'), 0),
					headerBarHeight
				);

				this.setInsidePageVar('current', 'scrollOffset', offset);
			}

			if (
				newScrollDirection &&
				this.getInsidePageVars('current', 'scrollDirection') !== newScrollDirection
			) {
				this.setInsidePageVar('current', 'scrollDirection', newScrollDirection);
			}

			this.setInsidePageVar('current', 'lastScrollPos', Math.max(0, scrollPos)); // do not allow values smaller than 0
			this.setInsidePageVar('current', 'onTopInsidePageHeader', scrollPos <= headerBarHeight);

			// @todo check if really needed
			if (this.ensureLastScroll) {
				clearTimeout(this.ensureLastScroll);
			}
			if (!lastScroll) {
				this.ensureLastScroll = setTimeout(() => {
					this.scrollEvent($event, true);
				}, 300);
			}
		},
		closeInsidePage() {
			this.$page.goBack();
		},
		transitionFinished(param: 'enter' | 'leave') {
			if (param === 'enter') {
				this.lastCardIndex = undefined;
			}
			this.EventBus.$emit(`transition-finished-${param}`);
		}
	},
	props: {
		companyMode: { type: Boolean, default: false }
	},
	watch: {
		topCardIndex: {
			handler(_newVal, oldVal) {
				this.lastCardIndex = oldVal;
			}
		},
		internalInsidePages: {
			handler(newValue) {
				if (Object.keys(this.insidePageVars).length > Object.keys(newValue).length) {
					// inside page removed from stack, clear up temporary variable
					Object.keys(this.insidePageVars).forEach(key => {
						if (parseInt(key, 10) >= Object.keys(newValue).length) {
							delete this.insidePageVars[key];
						}
					});
				} else {
					// new page

					nextTick(() => {
						this.myScrollToTop();
					});
				}
			},
			deep: true,
			immediate: true
		}
	}
});
</script>

<!--eslint-disable-next-line vue-scoped-css/require-scoped vue-scoped-css/enforce-style-type-->
<style lang="scss">
// modals have z-index 999, inside page "modals" needs to be higher than a modal
.z-1000 {
	z-index: 1000;
}

.insidepage {
	height: 100%;
}

.close-insidepage-mobile {
	@apply left-0 p-4 flex items-center;
}

// for onboarding flow
.close-insidepage-desktop {
	top: 70px;
	left: 1rem;
	padding: 0.75rem;
}

// for normal tabs
.has-tabs {
	.close-insidepage-desktop {
		top: 60px;
	}
}

.inside-page-forward-bottom-enter-active,
.inside-page-backward-bottom-leave-active,
.inside-page-forward-side-enter-active,
.inside-page-backward-side-leave-active {
	transition: transform 0.25s;
}

.inside-page-forward-bottom-enter-from,
.inside-page-backward-bottom-leave-to {
	transform: translateY(100%);
}

.inside-page-forward-side-enter-from,
.inside-page-backward-side-leave-to {
	transform: translateX(100%);
}

.mobile-header-bar {
	background: white;
	border-bottom: 1px solid $color-grey-light;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0;
	z-index: 100;
	overflow: hidden;
	height: 48px;
	h4 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: bold;
		line-height: 48px;
	}
}
</style>
