<template>
	<form id="signup-form-b2b" ref="signupFormB2B" @submit.stop.prevent="setupPayload">
		<HokSelect
			id="signup-company-gender"
			:value-promise="dataGenderLevels"
			choose-text="Anrede"
			required
			:autofocus="false"
			key-name="level"
			class="w-1/2 mb-4 pr-1.5 z-[19]"
			@input="setGender"
		/>
		<div class="flex space-x-3">
			<HokInput
				id="signup-company-firstname"
				v-model="firstname"
				:autofocus="false"
				required
				:submit-button="submitButton"
				type="text"
				name="firstname"
				class="flex-1 mb-0"
				question="Wie lautet Ihr Vorname?"
			>
				Vorname
			</HokInput>
			<HokInput
				id="signup-company-lastname"
				v-model="lastname"
				:autofocus="false"
				required
				:submit-button="submitButton"
				type="text"
				name="lastname"
				class="flex-1 mb-0"
				question="Wie lautet Ihr Familienname?"
			>
				Nachname
			</HokInput>
		</div>
		<div :class="{ 'flex flex-col sm:flex-row sm:space-x-3': isContactForm }">
			<HokInput
				id="signup-company-emailorphone"
				v-model.trim="email"
				:autofocus="false"
				required
				:submit-button="submitButton"
				type="text"
				name="email"
				:class="{ 'flex-1': isContactForm }"
				question="Geben Sie bitte Ihre E-Mail-Adresse ein"
			>
				E-Mail
			</HokInput>
			<EmailOrPhone
				id="signup-company-emailorphone"
				v-model.trim="phone"
				name="phone"
				phone-only
				browser-autocomplete="tel"
				:wrapper-classes="[phone && phone.length > 0 ? 'hok-tel-input' : 'hok-tel-input empty']"
				placeholder="Telefonnummer"
				:preferred-countries="['at', 'de', 'ch']"
				:autofocus="false"
				required
				valid-characters-only
				question="Geben Sie bitte Ihre Telefonnummer ein"
				class="force-full-width"
				:class="{ 'flex-1': isContactForm }"
				@input="phone = $event"
			>
				Telefonnummer
			</EmailOrPhone>
		</div>
		<div :class="{ 'flex flex-col': isContactForm }">
			<div class="flex items-start">
				<Autocomplete
					id="signup-company-organization"
					:value-promise="organizationSuggestion"
					value-label="name"
					value-id="_id"
					:disabled="
						!!(onboardingData && onboardingData.organization && onboardingData.organization.id)
					"
					:value="selectedOrganizationName"
					:autofocus="false"
					required
					question="Bitte Namen der Firma eingeben"
					class="force-full-width flex-1 mr-4"
					@input="setOrganizationName"
				>
					<template #addCustomValue="slotProps">
						Unternehmen "{{ slotProps.value }}" neu hinzufügen
					</template>
					Offizieller Firmenname
				</Autocomplete>
				<ToolTip
					styling="company"
					name="icon:info"
					color="grey-light"
					text="Bitte auf Korrektheit des Firmennamens achten, da dieser für Ihre Rechnung übernommen wird."
					class="my-auto"
					@tooltip-toggled.once="$emit('tooltip-toggled', 'organization-name')"
				/>
			</div>
			<div class="flex items-start my-4">
				<HokSelect
					id="signup-company-jobspermonth"
					:value-promise="dataJobsPerMonth"
					choose-text="Anzahl offener Stellen"
					required
					limit-dropdown-height
					:autofocus="false"
					class="force-full-width flex-1"
					key-name="source"
					@input="setJobsPerMonth"
				/>
				<ToolTip
					styling="company"
					name="icon:info"
					color="grey-light"
					text="Um Ihnen den bestmöglichen Service zu gewährleisten, fragen wir die Gesamtanzahl offener Stellen von Neukunden ab."
					class="ml-4 my-auto"
					@tooltip-toggled.once="$emit('tooltip-toggled', 'open-jobs')"
				/>
			</div>
		</div>
		<ErrorBox v-if="errorOnRequest || error" :fullwidth="false">
			<template v-if="errorOnRequest.title || error.title" #title>
				{{ errorOnRequest.title || error.title }}
			</template>
			{{ errorOnRequest.text || error.text }}
		</ErrorBox>
		<slot name="message" />
		<slot name="publish" />
		<HokCheckbox
			v-if="privacy.includes('company_register')"
			id="privacy-company-register"
			v-model="company_register"
			required
			class="text-xs mt-4"
			name="privacy-company-register"
		>
			Um ein kostenloses hokify Firmenkonto zu erstellen, bestätige ich, dass hokify meine Daten
			verarbeiten darf. Weiters nehme ich zur Kenntnis, dass ich für die Daten eingehender Bewerber
			im Sinne der Datenschutz-Grundverordnung als Verantwortlicher zu betrachten bin und alle
			dementsprechenden Pflichten zu erfüllen habe. Mit dem Login werden die
			<HokLink to="/disclaimer" target="_blank" class="underline"> AGB </HokLink> akzeptiert.
			<template #more>
				<HokLink to="/privacy#company-register" target="_blank" class="underline inline">
					Datenschutzerklärung &amp; Widerrufsrecht
				</HokLink>
			</template>
		</HokCheckbox>
		<HokCheckbox
			v-if="privacy.includes('company_digital_content')"
			id="privacy-company-digital-content"
			v-model="company_digital_content"
			required
			name="privacy-company-digital-content"
			class="text-xs max-w-md mt-4"
		>
			Um digitale Inhalte von hokify zu erhalten, bestätige ich, dass hokify meine Daten verarbeiten
			darf.
			<HokLink to="/privacy#company-digital-content" target="_blank" class="underline">
				Datenschutzerklärung &amp; Widerrufsrecht
			</HokLink>
		</HokCheckbox>
		<HokButton
			id="signup-company-send"
			class="mb-4"
			submit
			fullwidth="always"
			font-weight="normal"
			color="business"
		>
			{{ isContactForm ? 'Kontakt aufnehmen' : 'Registrieren' }}</HokButton
		>
		<HokButton
			v-if="!isContactForm"
			id="signup-company-abort"
			outline
			color="business"
			fullwidth="always"
			class="mb-4"
			font-weight="normal"
			@click.stop.prevent="$emit('go-back')"
		>
			Ich habe bereits einen Account
		</HokButton>
	</form>
</template>

<script lang="ts">
import Autocomplete from '@hokify/shared-components-nuxt3/lib/components/Autocomplete.vue';
import EmailOrPhone from '@hokify/shared-components-nuxt3/lib/components/EmailOrPhone.vue';
import ErrorBox from '@hokify/shared-components-nuxt3/lib/components/ErrorBox.vue';
import HokCheckbox from '@hokify/shared-components-nuxt3/lib/components/HokCheckbox.vue';
import HokInput from '@hokify/shared-components-nuxt3/lib/components/HokInput.vue';
import HokSelect from '@hokify/shared-components-nuxt3/lib/components/HokSelect.vue';
import ToolTip from '@hokify/shared-components-nuxt3/lib/components/ToolTip.vue';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { IB2BOnboardingData, ISignupPayload } from '../../../types/login';
import type { ILocalSignupParameters } from '../../../types/ilocal-signup-parameters';
import type { SupportedCompanyCase } from '../../../types/supported-company-case';
import type { Gender } from '../../../types/gender';
import { LoginState } from '../../../types/enums';

export default defineComponent({
	name: 'HokSignupFormB2B',
	components: {
		Autocomplete,
		EmailOrPhone,
		ErrorBox,
		HokCheckbox,
		HokInput,
		HokSelect,
		ToolTip
	},
	emits: ['tooltip-toggled', 'action', 'reset', 'send', 'go-back'],
	data() {
		const recaptchaWidgetV2Id = undefined as undefined | number;
		const jobsPerMonth = undefined as undefined | number;
		const gender = '' as string | null;
		const error = undefined as any | boolean;
		const {
			public: { development }
		} = useRuntimeConfig();

		return {
			error,
			email: this.onboardingData?.phone || '',
			phone: this.onboardingData?.email || '',
			firstname: '',
			lastname: '',
			gender,
			newOrganizationName: this.onboardingData?.organization?.id
				? ''
				: this.onboardingData?.organization?.name || '',
			selectedOrganizationName: this.onboardingData?.organization?.name || '',
			jobsPerMonth,
			registeredOrganizationId: this.onboardingData?.organization?.id || null,
			company_register: false,
			company_digital_content: false,
			submitButton: { label: 'weiter' },
			recaptchaWidgetV2Id,
			development
		};
	},
	computed: {
		isContactForm(): boolean {
			return this.companycase === 'moreinfo';
		},
		signupFormB2B() {
			return this.$refs.signupFormB2B as HTMLElement;
		}
	},
	methods: {
		scrollFormIntoView(error) {
			if (error?.text) {
				this.$nextTick(() => {
					this.signupFormB2B.scrollIntoView({ behavior: 'smooth' });
				});
			}
		},
		async setupPayload() {
			if (this.gender === '') {
				this.error = {
					title: 'Anrede notwendig',
					text: 'Bitte wählen Sie eine Anrede aus.'
				};
			} else if (
				!this.email ||
				this.email.length === 0 ||
				this.firstname.length === 0 ||
				this.lastname.length === 0
			) {
				this.error = {
					title: 'Name überprüfen',
					text: 'Bitte überprüfe deinen Vorname und deinen Nachname auf Vollständigkeit!'
				};
			} else if (this.phone === '') {
				this.error = {
					title: 'Angaben notwendig',
					text: 'Bitte überprüfen Sie Telefonnummer/E-Mail-Adresse.'
				};
			} else if (!this.newOrganizationName && !this.registeredOrganizationId) {
				this.error = {
					title: 'Firmenname notwendig',
					text: 'Bitte geben Sie den Firmennamen bekannt.'
				};
			} else if (this.jobsPerMonth === undefined) {
				this.error = {
					title: 'Antwort notwendig',
					text: 'Bitte geben Sie die Anzahl der offenen Stellen bekannt.'
				};
			} else if (this.privacy.includes('company_register') && !this.company_register) {
				this.error = {
					title: 'Zustimmung notwendig',
					text: 'Bitte Datenschutz akzeptieren um fortzufahren.'
				};
			} else if (
				this.privacy.includes('company_digital_content') &&
				!this.company_digital_content
			) {
				this.error = {
					title: 'Zustimmung notwendig',
					text: 'Bitte Datenschutz akzeptieren um fortzufahren.'
				};
			} else {
				this.error = false;

				const data: Omit<ILocalSignupParameters, 'recaptchaToken' | 'recaptchaVersion'> = {
					email: this.email,
					firstname: this.firstname,
					lastname: this.lastname,
					gender: !this.gender || this.gender === 'unicorn' ? null : (this.gender as Gender),
					companycase: (this.companycase || 'b2b') as SupportedCompanyCase,
					jobspermonth: this.jobsPerMonth
				};
				if (this.privacy.includes('company_digital_content')) {
					// use any since generated ILocalSignupParametersPrivacyCompanyDigitalContentEnum is generated in a weird way :)
					data.privacy_company_digital_content = this.company_digital_content as any;
				}
				if (this.privacy.includes('company_register')) {
					// use any since generated ILocalSignupParametersPrivacyCompanyRegisterEnum is generated in a weird way :)
					data.privacy_company_register = this.company_register as any;
				}
				if (this.newOrganizationName) {
					data.organization = this.newOrganizationName;
				} else if (this.registeredOrganizationId) {
					data.organizationId = this.registeredOrganizationId;
				}
				data.email = this.email;
				data.phone = this.phone;

				const payload: ISignupPayload = {
					state: LoginState.signup,
					data
				};

				if (this.development) {
					console.log('PAYLOAD', payload);
				}
				this.$nextTick(() => {
					this.$emit('send', payload);
				});
			}
		},
		dataJobsPerMonth() {
			return [
				{ value: '0 offene Stellen', id: 0 },
				{ value: '1 offene Stelle', id: 1 },
				{ value: '2-5 offene Stellen', id: 2 },
				{ value: '6-15 offene Stellen', id: 3 },
				{ value: '16-30 offene Stellen', id: 4 },
				{ value: '31-70 offene Stellen', id: 5 },
				{ value: '70+ offene Stellen', id: 6 }
			];
		},
		setJobsPerMonth(jobsPerMonth) {
			this.jobsPerMonth = jobsPerMonth.id;
		},
		dataGenderLevels() {
			return [
				{ value: 'Herr', id: 'male' },
				{ value: 'Frau', id: 'female' },
				{ value: 'Keine Angabe', id: 'unicorn' }
			];
		},
		setGender(gender) {
			this.gender = gender.id;
		},
		setOrganizationName($event, _, params) {
			this.selectedOrganizationName = $event;
			if (params && params.id) {
				this.newOrganizationName = '';
				this.registeredOrganizationId = params.id;
			} else if ($event) {
				this.newOrganizationName = $event;
				this.registeredOrganizationId = null;
			} else {
				console.error('no organization selected');
			}
		},
		onErrorChange(newVal) {
			this.scrollFormIntoView(newVal);
		},
		async organizationSuggestion(term) {
			return this.$nuxt.$valuesStore?.organizationSuggestion(term);
		}
	},
	props: {
		privacy: { type: Array, default: () => {} },
		companycase: { type: String, default: 'b2b' },
		errorOnRequest: {
			type: Object as PropType<{ title?: string; text?: string; code?: string }>,
			default: () => {}
		},
		onboardingData: { type: Object as PropType<IB2BOnboardingData>, default: () => {} }
	},
	watch: {
		error: [
			{
				handler: 'onErrorChange'
			}
		],
		errorOnRequest: [
			{
				handler: 'onErrorChange'
			}
		]
	}
});
</script>

<style lang="scss" scoped>
.force-full-width {
	::v-deep(.input--sae),
	::v-deep(.hok-tel-input),
	::v-deep(.select-element) {
		max-width: 100%;
	}
}
</style>
