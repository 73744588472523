<template>
	<main>
		<div class="tabs">
			<Tabs
				:align-center="true"
				class="fullwidth2point5tabsonphone"
				name="joblist"
				:options="{ defaultTabHash }"
				@changed="selectTab"
			>
				<Tab id="matches" key="matches" :badge="unseenMsgs" name="Bewerbungen" url="/pwa/joblist">
					<ContentContainer size="large">
						<ApplicationFilterBar
							key="appliedFilter"
							ref="appliedFilter"
							:state-filter="visibleAppliedStates"
							@set-filter="setFilter"
						/>

						<Spinner v-if="isLoadingApplied || isLoadingActivesourced" />
						<template v-else-if="appliedMatchGroups && appliedMatchGroups.length">
							<HokList
								:groups="appliedMatchGroups"
								:on-refresh="refreshApplied"
								@hide-application="deleteApplicationDialog"
							>
								<template #before>
									<CvFails v-if="user" :user="user" class="mt-4" />
								</template>
								<template v-if="appliedJobsLength === 1" #after
									><HokLink
										:to="
											savedMatchGroups && savedMatchGroups.length ? '/pwa/joblist/saved' : '/pwa'
										"
										class="flex items-center pt-5 border-t border-color-grey-lightest"
										:class="$isMobile.any ? 'pb-6 border-b' : 'px-8 pb-12'"
									>
										<NuxtImg
											src="/svg/usp-letter.svg"
											width="64"
											height="64"
											:class="$isMobile.any ? 'mr-4' : 'mr-6'"
										/>
										<div>
											<p class="font-bold mb-0 text-color-text">Nutze die Wartezeit</p>
											<p class="mb-0 text-color-text text-sm">
												Und bewirb dich in 3 Minuten bei <br />
												einem weiteren Unternehmen.
											</p>
											<p class="mb-0 text-sm">
												{{
													savedMatchGroups && savedMatchGroups.length
														? 'Zu den gespeicherten Jobs'
														: 'Weitere Jobs ansehen'
												}}
											</p>
										</div>
									</HokLink>
								</template>
							</HokList>
						</template>

						<ContentContainer v-else size="small">
							<template v-if="appliedFilter.date === 'Alle'">
								<h3 class="text-center mt-8 mb-0">
									Du hast noch keine Bewerbungen. <br />Starte jetzt mit der Jobsuche.
								</h3>
								<NuxtImg
									alt="Jobalarm"
									src="/shared-components-nuxt3/images/jobalarm.png"
									class="mb-4 mx-w-md"
								/>
								<HokButton class="mx-auto" fullwidth="mobile" color="main" to="/pwa"
									>Jobs finden</HokButton
								>
							</template>
							<template v-else>
								<h3 class="text-center my-8">
									Wir konnten keine Bewerbungen im ausgewählten Zeitrum finden.
								</h3>
								<HokButton
									class="mx-auto"
									fullwidth="mobile"
									color="main"
									@click="resetDateFilter('appliedFilter')"
									>Filter zurücksetzen</HokButton
								>
							</template>
						</ContentContainer>
					</ContentContainer>
				</Tab>
				<Tab
					id="saved"
					key="saved"
					:notification-badge="uiStateStore.unseenSavedJobs"
					name="Gespeichert"
					url="/pwa/joblist/saved"
				>
					<ContentContainer size="large">
						<ApplicationFilterBar
							key="savedFilter"
							ref="savedFilter"
							:state-filter="visibleSavedStates"
							@set-filter="setFilter"
						/>
						<Spinner v-if="isLoadingSaved" />
						<HokList
							v-else-if="savedMatchGroups && savedMatchGroups.length"
							:groups="savedMatchGroups"
							:on-refresh="refreshSaved"
						>
							<template #before>
								<CvFails v-if="user" :user="user" class="mt-4" />
							</template>
						</HokList>
						<ContentContainer v-else size="small">
							<template v-if="savedFilter.date === 'Alle'">
								<h3 class="text-center mt-8 mb-0">
									Du hast noch keine Jobs oder Firmen gespeichert. Starte jetzt mit der Jobsuche.
								</h3>
								<NuxtImg
									alt="Jobalarm"
									src="/shared-components-nuxt3/images/jobalarm.png"
									class="mb-4 mx-w-md"
								/>
								<HokButton class="mx-auto" fullwidth="mobile" color="main" to="/pwa"
									>Jobs finden</HokButton
								>
							</template>
							<template v-else>
								<h3 class="text-center my-8">
									Wir konnten keine Jobs oder Firmen finden, die du im ausgewählten Zeitraum
									gespeichert hast.
								</h3>
								<HokButton
									class="mx-auto"
									fullwidth="mobile"
									color="main"
									@click="resetDateFilter('savedFilter')"
									>Filter zurücksetzen</HokButton
								>
							</template>
						</ContentContainer>
					</ContentContainer>
				</Tab>
				<Tab id="jobalarm" key="jobalarm" name="Jobalarm" url="/pwa/joblist/jobalarm">
					<ContentContainer
						v-if="isLoadingJobAlarm || (jobAlarmGroups && jobAlarmGroups.length)"
						size="large"
						class="pb-24"
					>
						<Spinner v-if="isLoadingJobAlarm" />
						<template v-else-if="jobAlarmGroups && jobAlarmGroups.length">
							<HokList :groups="jobAlarmGroups" :on-refresh="refreshJobAlarmSubscriptions" />
							<HokButton
								class="mx-auto mb-4"
								fullwidth="mobile"
								color="main"
								@click="openJobAlarmPage"
								>Jobalarm erstellen</HokButton
							>
							<HokButton
								class="mx-auto"
								color="main"
								is-text
								fullwidth="mobile"
								to="/pwa/profile/settings#jobAlarmInterval"
								>Einstellungen</HokButton
							>
						</template>
					</ContentContainer>
					<ContentContainer v-else size="medium">
						<h3 class="text-center mt-8">Du hast noch keinen Jobalarm aktiviert. <br /></h3>
						<h4 class="text-sm text-center font-normal">
							Du möchtest keine aktuellen Jobs mehr verpassen?<br />
							Dann erstelle jetzt deinen ersten Jobalarm!
						</h4>
						<NuxtImg
							alt="Jobalarm"
							src="/shared-components-nuxt3/images/jobalarm.png"
							class="mb-4 mx-w-md"
						/>
						<HokButton class="mx-auto" fullwidth="mobile" color="main" @click="openJobAlarmPage"
							>Jobalarm erstellen</HokButton
						>
					</ContentContainer>
				</Tab>
			</Tabs>
		</div>
		<HokModal :adaptive="true" show-close-butto :name="`delete-application-modal`"
			><h3 class="text-center">Bewerbung ausblenden</h3>
			<p>
				Willst du diese Bewerbung wirklich ausblenden? Die Bewerbung wird somit nicht mehr in deiner
				Liste angezeigt, dennoch ist die Bewerbung bereits beim jeweiligen Arbeitgeber eingegangen.
				Dieser Vorgang kann nicht rückgängig gemacht werden
			</p>
			<div class="flex space-x-2">
				<HokButton fullwidth="always" color="main" outline @click="deleteApplication">OK</HokButton>
				<HokButton
					fullwidth="always"
					color="main"
					:class="{ 'mb-2 ': $isMobile.any }"
					@click="$modal.hide('delete-application-modal')"
					>Abbrechen</HokButton
				>
			</div>
		</HokModal>
	</main>
</template>

<script lang="ts">
import HokList from '@hokify/shared-components-nuxt3/lib/components/HokList.vue';
import ContentContainer from '@hokify/shared-components-nuxt3/lib/components/ContentContainer.vue';
import Tabs from '@hokify/shared-components-nuxt3/lib/components/Tabs.vue';
import Tab from '@hokify/shared-components-nuxt3/lib/components/Tab.vue';
import CvFails from '@hokify/pwa-core-nuxt3/components/CvFails.vue';
import type { IHokListGroupEntry } from '@hokify/shared-components-nuxt3/lib/types/HokListGroupEntry';
import type {
	IAPIJobAlarmSubscription,
	IAPIJobFieldTree,
	IAPIJobFilter,
	IAPILoginUser,
	IAPIMatchForJobSeekerListEntry
} from '@hokify/common';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { mapStores } from 'pinia';
import { markRaw, defineAsyncComponent } from 'vue';
import JobSeekerMatchListItem from '@/components/user/JobSeekerMatchListItem.vue';
import JobAlarmListItem from '@/components/user/JobAlarmListItem.vue';
import ApplicationFilterBar from '@/components/user/ApplicationFilterBar.vue';
import { useMatchesStore } from '@/stores/matches';
import { useMessagesStore } from '@/stores/messages';
import { useUiStateStore } from '@/stores/uistate';
import { useUserJobFilterStore } from '@/stores/user-job-filter';
import { useUserJobAlarmStore } from '@/stores/user-job-alarm';
import { useValuesStore } from '@/stores/values';
import { useUserProfileStore } from '@/stores/user-profile';
import { defineNuxtComponent, definePageMeta, useSeoMeta, useRoute } from '#imports';

function hasResetDateFilter(obj: any): obj is { resetDateFilter(): void } {
	return obj && typeof obj.resetDateFilter === 'function';
}

export default defineNuxtComponent({
	setup() {
		definePageMeta({
			layout: 'user',
			path: '/pwa/joblist/:tab?',
			middleware: ['auth']
		});

		useSeoMeta({
			title: 'Bewerbungen, Gespeicherte Jobs, Jobalarme | hokify'
		});
	},
	components: {
		ContentContainer,
		Tabs,
		Tab,
		HokList,
		CvFails,
		ApplicationFilterBar
	},
	async asyncData({ $pinia }) {
		const { params } = useRoute();

		let tab;

		switch (params.tab) {
			case 'matches':
			case 'saved':
			case 'jobalarm':
				tab = params.tab;
				break;
			default: {
				tab = 'matches';
			}
		}

		const valuesStore = useValuesStore($pinia);
		const jobFields = await valuesStore.getJobFields();

		return { defaultTabHash: tab, jobFields };
	},
	data() {
		const savedMatchGroupsArray: { id: string; title: string; amount: number }[] = [
			{ id: 'all', title: 'Alle', amount: 0 },
			{ id: 'savedJobs', title: 'Gespeicherte Jobs', amount: 0 },
			{ id: 'savedCompanies', title: 'Gespeicherte Firmen', amount: 0 },
			{ id: 'expired', title: 'Nicht mehr verfügbar', amount: 0 }
		];
		const appliedMatchGroupsArray: { id: string; title: string; amount: number }[] = [
			{ id: 'all', title: 'Alle', amount: 0 },
			{ id: 'activesourced', title: 'An dir interessiert', amount: 0 },
			{ id: 'pending', title: 'In Bearbeitung', amount: 0 },
			{ id: 'waiting', title: 'Warten auf Rückmeldung', amount: 0 },
			{ id: 'rejected', title: 'Absagen', amount: 0 },
			{ id: 'active', title: 'Aktive Bewerbungen', amount: 0 },
			{ id: 'incomplete', title: 'Nicht versendete Bewerbungen', amount: 0 }
		];
		const date: { id: string; value: Date }[] = [
			{ id: '24hours', value: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) }, // 24 hours ago
			{ id: '7days', value: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000) }, // 7 days ago
			{ id: 'lastMonth', value: new Date(new Date().getTime() - 31 * 24 * 60 * 60 * 1000) }, // 31 days ago
			{ id: 'currentYear', value: new Date(new Date().getTime()) }, // today (= currentYear)
			{ id: 'lastYear', value: new Date(new Date().getTime() - 12 * 31 * 24 * 60 * 60 * 1000) }, // 14 months / 1 year ago
			{ id: 'twoYearsAgo', value: new Date(new Date().getTime() - 24 * 31 * 24 * 60 * 60 * 1000) }, // 24 months / 2 years ago
			{
				id: 'olderThan2years',
				value: new Date(new Date().getTime() - 24 * 31 * 24 * 60 * 60 * 1000)
			} // 24 months / 2 years
		];
		const currentTab = 'matches' as 'matches' | 'saved' | 'jobalarm';
		const isLoadingActivesourced = false as boolean | 'error';
		const isLoadingJobAlarm = false as boolean | 'error';
		const isLoadingSaved = false as boolean | 'error';
		const isLoadingApplied = false as boolean | 'error';
		const constappliedFilter = { state: 'Alle', date: 'Alle' };
		const savedFilter = { state: 'Alle', date: 'Alle' };
		const appliedFilter = { state: 'Alle', date: 'Alle' };
		const firstTime = true;
		const jobFields = undefined as undefined | IAPIJobFieldTree[];
		const defaultTabHash = 'matches';
		const matchToDelete = undefined as undefined | IAPIMatchForJobSeekerListEntry;

		return {
			isLoadingApplied,
			isLoadingSaved,
			isLoadingJobAlarm,
			isLoadingActivesourced,
			firstTime,
			jobFields,
			currentTab,
			constappliedFilter,
			appliedFilter,
			savedFilter,
			currentDateTime: new Date().getTime(),
			date,
			appliedMatchGroupsArray,
			savedMatchGroupsArray,
			defaultTabHash,
			matchToDelete,
			EventBus
		};
	},
	// beforeUnmount() {
	// 	this.EventBus.$off('set-job-filter', this.setJobFilter);
	// },
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		allSubscriptions(): IAPIJobAlarmSubscription[] {
			return this.userJobAlarmStore.allSubscriptions;
		},
		getJobFilter(): IAPIJobFilter | undefined {
			return this.userProfileStore.obj?.jobFilter;
		},
		visibleAppliedStates() {
			return this.appliedMatchGroupsArray.filter(
				appliedMatchGroups => appliedMatchGroups.amount > 0
			);
		},
		visibleSavedStates() {
			return this.savedMatchGroupsArray.filter(savedMatchGroups => savedMatchGroups.amount > 0);
		},
		unseenMsgs() {
			return Object.keys(this.messagesStore.matchesWithUnseenMsgs).length;
		},
		savedMatchGroups() {
			const matchGroups = {
				savedJobs: [] as IAPIMatchForJobSeekerListEntry[],
				savedCompanies: [] as IAPIMatchForJobSeekerListEntry[],
				expired: [] as IAPIMatchForJobSeekerListEntry[]
			};

			this.matchesStore.getAll.forEach(match => {
				if (
					match.likeuser === 'saved' &&
					match.relation.type === 'job' &&
					match.relation.obj.internal.isPublished
				) {
					matchGroups.savedJobs.push(match);
				} else if (match.likeuser === 'saved' && match.relation.type === 'company') {
					matchGroups.savedCompanies.push(match);
				} else if (match.likeuser === 'saved' || match.likeuser === 'needreview') {
					matchGroups.expired.push(match);
				}
			});

			let groups: IHokListGroupEntry[] = [];

			this.savedMatchGroupsArray.some(savedMatchGroup => {
				if (savedMatchGroup.id === 'all') {
					savedMatchGroup.amount =
						(matchGroups.savedJobs?.length || 0) +
						(matchGroups.savedCompanies?.length || 0) +
						(matchGroups.expired?.length || 0);
					return true;
				}
				return false;
			});

			if (matchGroups.savedJobs?.length) {
				this.savedMatchGroupsArray.some(savedMatchGroup => {
					if (savedMatchGroup.id === 'savedJobs') {
						savedMatchGroup.amount = matchGroups.savedJobs.length;
						return true;
					}
					return false;
				});
				groups.push({
					id: 'savedJobs',
					title: 'Gespeicherte Jobs',
					disabled: false,
					items: matchGroups.savedJobs.map(match => ({
						id: match._id,
						component: {
							is: JobSeekerMatchListItem,
							props: { match }
						}
					}))
				});
			}

			if (matchGroups.savedCompanies?.length) {
				this.savedMatchGroupsArray.some(savedMatchGroup => {
					if (savedMatchGroup.id === 'savedCompanies') {
						savedMatchGroup.amount = matchGroups.savedCompanies.length;
						return true;
					}
					return false;
				});
				groups.push({
					id: 'savedCompanies',
					title: 'Gespeicherte Firmen',
					disabled: false,
					items: matchGroups.savedCompanies.map(match => ({
						id: match._id,
						component: {
							is: JobSeekerMatchListItem,
							props: { match }
						}
					}))
				});
			}

			if (matchGroups.expired?.length) {
				this.savedMatchGroupsArray.some(savedMatchGroup => {
					if (savedMatchGroup.id === 'expired') {
						savedMatchGroup.amount = matchGroups.expired.length;
						return true;
					}
					return false;
				});
				groups.push({
					id: 'expired',
					title: 'Nicht mehr verfügbar',
					disabled: true,
					items: matchGroups.expired.map(match => ({
						id: match._id,
						component: {
							is: JobSeekerMatchListItem,
							props: { match }
						}
					}))
				});
			}

			if (this.savedFilter.state !== 'Alle' && this.savedFilter.state !== 'all') {
				groups = groups.filter(group => group.id === this.savedFilter.state);
			}

			if (this.savedFilter.date !== 'Alle' && this.savedFilter.date !== 'all') {
				const date = this.date.find(d => d.id === this.savedFilter.date);
				if (!date) return;
				groups.forEach(group => {
					group.items = group.items.filter(item => {
						if (date.id === 'currentYear' || date.id === 'lastYear' || date.id === 'twoYearsAgo') {
							return (
								new Date(item.component.props.match.dateuseraction).getFullYear() ===
								date.value.getFullYear()
							);
						}
						if (date.id === 'olderThan2years') {
							return (
								new Date(item.component.props.match.dateuseraction).getTime() < date.value.getTime()
							);
						}
						return (
							new Date(item.component.props.match.dateuseraction).getTime() > date.value.getTime()
						);
					});
				});
			}

			// only show groups with items in it
			groups = groups.filter(group => group.items.length > 0);
			return groups;
		},
		appliedMatchGroups() {
			const matches = this.matchesStore.getAll.filter(
				match =>
					match &&
					(match.likeuser === 'true' ||
						match.likeuser === 'pending' ||
						match.likeuser === 'needreview' ||
						match.likecompany)
			);

			const matchGroups: {
				pending: IAPIMatchForJobSeekerListEntry[];
				waiting: IAPIMatchForJobSeekerListEntry[];
				rejected: IAPIMatchForJobSeekerListEntry[];
				active: IAPIMatchForJobSeekerListEntry[];
				incomplete: IAPIMatchForJobSeekerListEntry[];
				activesourced: IAPIMatchForJobSeekerListEntry[];
			} = {
				pending: [],
				waiting: [],
				rejected: [],
				active: [],
				incomplete: [],
				activesourced: []
			};

			matches.forEach(match => {
				if (match.likeuser === 'pending') {
					matchGroups.pending.push(match);
				} else if (
					match.likeuser === 'needreview' &&
					match.relation.type === 'job' &&
					match.relation.obj.internal.isPublished
				) {
					matchGroups.incomplete.push(match);
				} else if (match.actionToken === 'company') {
					matchGroups.waiting.push(match);
				} else if (match.actionToken === 'cancel') {
					matchGroups.rejected.push(match);
				} else if (match.likecompany && !match.likeuser) {
					matchGroups.activesourced.push(match);
				} else {
					matchGroups.active.push(match);
				}
			});

			let groups: IHokListGroupEntry[] = [];

			this.appliedMatchGroupsArray.some(appliedMatchGroup => {
				if (appliedMatchGroup.id === 'all') {
					appliedMatchGroup.amount =
						(matchGroups.incomplete?.length || 0) +
						(matchGroups.active?.length || 0) +
						(matchGroups.pending?.length || 0) +
						(matchGroups.waiting?.length || 0) +
						(matchGroups.rejected?.length || 0) +
						(matchGroups.activesourced?.length || 0);

					return true;
				}
				return false;
			});

			if (matchGroups.activesourced?.length) {
				this.appliedMatchGroupsArray.some(appliedMatchGroup => {
					if (appliedMatchGroup.id === 'activesourced') {
						appliedMatchGroup.amount = matchGroups.activesourced.length;
						return true;
					}
					return false;
				});
				groups.push({
					id: 'activesourced',
					title: 'An dir interessiert',
					disabled: false,
					items: matchGroups.activesourced.map(match => ({
						id: match._id,
						component: {
							is: JobSeekerMatchListItem,
							props: { match }
						}
					}))
				});
			}

			if (matchGroups.incomplete?.length) {
				this.appliedMatchGroupsArray.some(appliedMatchGroup => {
					if (appliedMatchGroup.id === 'incomplete') {
						appliedMatchGroup.amount = matchGroups.incomplete.length;
						return true;
					}
					return false;
				});
				groups.push({
					id: 'incomplete',
					title: 'Nicht versendete Bewerbungen',
					disabled: false,
					items: matchGroups.incomplete.map(match => ({
						id: match._id,
						component: {
							is: JobSeekerMatchListItem,
							props: { match }
						}
					}))
				});
			}

			if (matchGroups.active?.length) {
				this.appliedMatchGroupsArray.some(appliedMatchGroup => {
					if (appliedMatchGroup.id === 'active') {
						appliedMatchGroup.amount = matchGroups.active.length;
						return true;
					}
					return false;
				});
				groups.push({
					id: 'active',
					title: 'Aktive Bewerbungen',
					disabled: false,
					items: matchGroups.active.map(match => ({
						id: (match as any)._id,
						component: {
							is: JobSeekerMatchListItem,
							props: { match }
						}
					}))
				});
			}

			if (matchGroups.pending?.length) {
				this.appliedMatchGroupsArray.some(appliedMatchGroup => {
					if (appliedMatchGroup.id === 'pending') {
						appliedMatchGroup.amount = matchGroups.pending.length;
						return true;
					}
					return false;
				});
				groups.push({
					id: 'pending',
					title: 'In Bearbeitung',
					disabled: false,
					items: matchGroups.pending.map(match => ({
						id: match._id,
						component: {
							is: JobSeekerMatchListItem,
							props: { match }
						}
					}))
				});
			}

			if (matchGroups.waiting?.length) {
				this.appliedMatchGroupsArray.some(appliedMatchGroup => {
					if (appliedMatchGroup.id === 'waiting') {
						appliedMatchGroup.amount = matchGroups.waiting.length;
						return true;
					}
					return false;
				});
				groups.push({
					id: 'waiting',
					title: 'Warten auf Rückmeldung',
					disabled: false,
					items: matchGroups.waiting.map(match => ({
						id: match._id,
						component: {
							is: JobSeekerMatchListItem,
							props: { match }
						}
					}))
				});
			}

			if (matchGroups.rejected?.length) {
				this.appliedMatchGroupsArray.some(appliedMatchGroup => {
					if (appliedMatchGroup.id === 'rejected') {
						appliedMatchGroup.amount = matchGroups.rejected.length;
						return true;
					}
					return false;
				});
				groups.push({
					id: 'rejected',
					title: 'Absagen',
					disabled: false,
					items: matchGroups.rejected.map(match => ({
						id: match._id,
						component: {
							is: JobSeekerMatchListItem,
							props: { match }
						}
					}))
				});
			}

			if (this.appliedFilter.state !== 'Alle' && this.appliedFilter.state !== 'all') {
				groups = groups.filter(group => group.id === this.appliedFilter.state);
			}

			if (this.appliedFilter.date !== 'Alle' && this.appliedFilter.date !== 'all') {
				const date = this.date.find(d => d.id === this.appliedFilter.date);
				if (!date) return;
				groups.forEach(group => {
					group.items = group.items.filter(item => {
						if (date.id === 'currentYear' || date.id === 'lastYear' || date.id === 'twoYearsAgo') {
							return (
								new Date(
									item.component.props.match.dateuseraction ||
										item.component.props.match.datecompanyaction
								).getFullYear() === date.value.getFullYear()
							);
						}
						if (date.id === 'olderThan2years') {
							return (
								new Date(item.component.props.match.dateuseraction).getTime() < date.value.getTime()
							);
						}
						return (
							new Date(item.component.props.match.dateuseraction).getTime() > date.value.getTime()
						);
					});
				});
			}

			// only show groups with items in it
			groups = groups.filter(group => group.items.length > 0);
			return groups;
		},
		jobAlarmGroups() {
			const subscriptions = this.allSubscriptions || [];

			const matchGroups = { subscriptions: [] as any[] };

			matchGroups.subscriptions = [];

			if (subscriptions && subscriptions.length) {
				subscriptions.forEach(subscription => {
					matchGroups.subscriptions.push(subscription);
				});
			}

			const groups: IHokListGroupEntry[] = [];

			if (matchGroups.subscriptions?.length) {
				groups.push({
					id: 'jobalarm',
					title: 'Meine Jobalarme',
					disabled: false,
					items: matchGroups.subscriptions.map(subscription => ({
						id: subscription._id,
						component: {
							is: JobAlarmListItem,
							props: { subscription }
						}
					}))
				});
			}

			return groups;
		},
		appliedJobsLength(): number {
			const appliedJobs = this.matchesStore.getAll.filter(match => match.likeuser !== 'saved');

			return appliedJobs.length;
		},
		...mapStores(
			useMatchesStore,
			useMessagesStore,
			useUiStateStore,
			useUserJobFilterStore,
			useUserJobAlarmStore,
			useUserProfileStore,
			useValuesStore
		)
	},
	created() {
		this.isLoadingApplied = true;
		this.isLoadingSaved = true;
		this.isLoadingJobAlarm = true;
	},
	async mounted() {
		try {
			// fill store witch matches
			const pSave = this.matchesStore
				.getSaved()
				.then(() => {
					this.isLoadingSaved = false;
				})
				.catch(err => {
					this.isLoadingSaved = 'error';
					this.$nuxt.$errorHandler(err);
				});

			const pApplied = this.matchesStore
				.getApplied()
				.then(() => {
					this.isLoadingApplied = false;
				})
				.catch(err => {
					this.isLoadingApplied = 'error';
					this.$nuxt.$errorHandler(err);
				});

			const pActivesourced = this.matchesStore
				.getActivesourced()
				.then(() => {
					this.isLoadingActivesourced = false;
				})
				.catch(err => {
					this.isLoadingActivesourced = 'error';
					this.$nuxt.$errorHandler(err);
				});

			const pJobAlarm = this.userJobAlarmStore
				.getJobAlarmSubscriptions()
				.then(() => {
					this.isLoadingJobAlarm = false;
				})
				.catch(err => {
					this.isLoadingJobAlarm = 'error';
					this.$nuxt.$errorHandler(err);
				});

			// if error, reload saved/applied/jobAlarmSubscriptions list
			if (this.isLoadingApplied === 'error') {
				await this.matchesStore.getSaved();
			}
			if (this.isLoadingSaved === 'error') {
				await this.matchesStore.getApplied();
			}
			if (this.isLoadingJobAlarm === 'error') {
				await this.userJobAlarmStore.getJobAlarmSubscriptions();
			}

			// load oStats
			await this.messagesStore.updateOStats();

			await Promise.all([pSave, pApplied, pJobAlarm, pActivesourced]);
		} catch (err) {
			this.$nuxt.$errorHandler(err);
		}
	},
	methods: {
		async refreshSaved() {
			return this.matchesStore.getSaved(true);
		},
		async refreshApplied() {
			// updateOstats too, if user pulls-to-reload
			this.messagesStore.updateOStats(true);
			return this.matchesStore.getApplied(true);
		},
		async refreshJobAlarmSubscriptions(): Promise<void> {
			this.userJobAlarmStore.getJobAlarmSubscriptions(true);
		},
		selectTab({ tab }) {
			if (tab.id === 'saved') {
				this.currentTab = 'saved';
				this.uiStateStore.updateUnseenSavedJobs(false);
			} else if (tab.id === 'matches') {
				this.currentTab = 'matches';
			} else if (tab.id === 'jobalarm') {
				this.currentTab = 'jobalarm';
			}
			if (import.meta.client) {
				this.EventBus.$emit('select-tab', tab.url);
			}
		},
		async openJobAlarmPage() {
			if (!this.jobFields.length) {
				this.jobFields = await this.valuesStore.getJobFields();
			}
			const jobAlarmPage = markRaw(
				defineAsyncComponent(
					() => import('@hokify/shared-components-nuxt3/lib/pages/jobAlarmPage.vue')
				)
			);
			try {
				await this.$page.push(
					jobAlarmPage,
					{
						selected: this.getJobFilter,
						jobFields: this.jobFields,
						getPossibleFilter: this.userJobFilterStore.getPossibleFilter,
						user: this.user
					},
					{
						pageTitle: 'Jobalarm einstellen',
						name: 'set-job-alarm',
						done: () => {
							this.EventBus.$off('set-job-filter', this.setJobFilter);
						}
					}
				);
				this.EventBus.$on('set-job-filter', this.setJobFilter);
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		async setJobFilter(jobFilterObj) {
			try {
				await this.userJobAlarmStore.setJobAlarm(jobFilterObj);
				this.$router.push({ path: '/pwa/joblist/jobalarm#jobalarm' });
				this.$snack.success({
					title: 'Jobalarm erstellt!',
					text: 'Du bekommst ab jetzt eine Mitteilung, wenn wir einen neuen Job für dich haben, der zu deinem Filter passt.'
				});
				this.$trackUserEvent?.('activate_jobalarm_completed', {
					pageElement: 'job-alarm-list'
				});
				this.EventBus.$off('set-job-filter', this.setJobFilter);
			} catch (err: any) {
				console.error('setJobalarm failed', err);
				this.$nuxt.$errorHandler(
					err,
					'Beim setzen deines Jobalarms ist ein Fehler aufgetreten. Bitte versuch es später noch einmal.'
				);
			}
		},
		setFilter(id: string, filter: 'state' | 'date') {
			if (this.currentTab === 'matches') {
				this.appliedFilter[filter] = id;
			} else if (this.currentTab === 'saved') {
				this.savedFilter[filter] = id;
			}
		},
		resetDateFilter(ref: 'appliedFilter' | 'savedFilter') {
			if (ref === 'appliedFilter' && hasResetDateFilter(this.$refs.appliedFilter)) {
				this.$refs.appliedFilter?.resetDateFilter();
			} else if (ref === 'savedFilter' && hasResetDateFilter(this.$refs.savedFilter)) {
				this.$refs.savedFilter?.resetDateFilter();
			}
			this[ref].date = 'Alle';
		},
		deleteApplicationDialog(match: IAPIMatchForJobSeekerListEntry) {
			this.$modal.show('delete-application-modal');
			this.matchToDelete = match;
		},
		async deleteApplication() {
			await this.matchesStore.deleteMatch({
				match: this.matchToDelete
			});
			this.$modal.hide('delete-application-modal');
			this.$snack.danger({
				text: `Bewerbung für "${this.matchToDelete?.relation?.obj?.name}" wurde gelöscht!`
			});
		}
	}
});
</script>
