<template>
	<div class="tabs-component h-full">
		<div
			v-if="headline"
			class="text-center bg-white border-b border-color-grey-light py-3 font-bold text-lg"
		>
			{{ headline }}
		</div>
		<ul role="tablist" class="tabs-component-tabs" :class="tablistClass">
			<div
				ref="tabs"
				:class="{ 'text-center': alignCenter }"
				class="xl:max-w-5xl mx-auto tabs-header-container"
			>
				<li
					v-for="(tab, index) in elements"
					v-show="tab.isVisible"
					:ref="`tab-${index}`"
					:key="computeHashForTab(tab)"
					:class="{
						'is-active': tab.isActive || (nonActive && computeHashForTab(tab) === activeTabHash),
						'is-disabled': tab.isDisabled
					}"
					class="tabs-component-tab"
					role="presentation"
				>
					<a
						ref="tab"
						:aria-controls="computeHashForTab(tab)"
						:aria-selected="tab.isActive || (nonActive && computeHashForTab(tab) === activeTabHash)"
						:class="`tabs-component-tab-a cursor-pointer ${tab.badge ? 'tabs-with-badges' : ''} ${
							smallSpacing ? 'smallspacing' : ''
						}`"
						role="tab"
						@click="selectTab(computeHashForTab(tab), $event)"
						><strong v-if="tab && tab.id && tab.id === 'activesourcing'" class="italic">{{
							computeHeaderForTab(tab)
						}}</strong>
						<template v-else>
							{{ computeHeaderForTab(tab) }}
						</template>
						<client-only>
							<span
								v-if="tab.badge || tab.notificationBadge"
								:class="`badge cursor-pointer ${
									tab.notificationBadge ? 'notification-badge' : ''
								} ${tab.infoBadge ? 'info-badge' : ''} ${tab.textBadge ? 'text-badge' : ''}`"
								@click="selectTab(computeHashForTab(tab), $event)"
							>
								{{
									tab && tab.badge && typeof tab.badge === 'number' && tab.badge > 9
										? '9+'
										: tab.badge
								}}
							</span>
							<HokIcon
								v-if="tab.addCheckmark"
								name="icon:swipe-right"
								class="inline fill-main"
								:size="4"
							/>
						</client-only>
					</a>
				</li>
			</div>
		</ul>
		<div class="tabs-component-panels">
			<slot></slot>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

function isHTMLElement(el): el is HTMLElement {
	return el && el instanceof HTMLElement;
}

function isTabComponent(
	obj: any
): obj is { isDisabled?: boolean; isActive?: boolean; url?: string } {
	return obj && typeof obj === 'object'; // && obj.isDisabled !== undefined;
}

const stateLastSelectedTab = {};

export default defineComponent({
	name: 'Tabs',
	emits: ['changed', 'clicked', 'tab-changed'],
	data() {
		const indexOfPageTabs = undefined as any;
		const recomputeElements: Date = new Date();
		return {
			recomputeElements,
			needRecomputing: false,
			direction: '',
			unsetHeight: false,
			activeTabHash: '',
			activeTabIndex: 0,
			lastActiveTabHash: '',
			blockTabChange: false,
			selectedTabIndex: -1,
			indexOfPageTabs
		};
	},
	computed: {
		nonActive(): boolean {
			return !(this.elements && this.elements.some(e => e.isActive));
		},
		elements(): {
			readonly id?: string;
			readonly name: string;
			readonly prefix?: string;
			readonly suffix?: string;
			readonly url?: string;
			readonly isDisabled?: boolean;
			readonly notificationBadge?: boolean;
			readonly infoBadge?: boolean;
			readonly textBadge?: boolean;
			readonly scrollToTop?: boolean;
			readonly badge?: [number, string];
			readonly isVisible?: boolean;
			readonly isActive?: boolean;
		}[] {
			return (
				(this.$slots.default?.()?.length &&
					this.$slots
						.default?.()
						.map(slot => {
							const propsData = (slot.type as any)?.name === 'Tab' && slot.props;

							return (
								propsData && {
									name: '',
									isVisible: true, // default is true
									isActive: false, // default is false
									...propsData
								}
							);
						})
						.filter(f => !!f)) ||
				[]
			);
		}
	},
	created() {
		if (this.elements?.length) {
			this.startTabsView();
		}
	},
	updated() {
		const activeTabIndex = this.getActiveTabIndex();
		// only on mobile, set current tab in tab panel into view
		if (
			this.$isMobile.any &&
			this.$refs[`tab-${activeTabIndex}`]?.[0] &&
			isHTMLElement(this.$refs[`tab-${activeTabIndex}`]?.[0]) &&
			this.selectedTabIndex !== activeTabIndex
		) {
			this.$refs[`tab-${activeTabIndex}`]?.[0]?.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'center'
			});
		}

		this.selectedTabIndex = this.getActiveTabIndex();
	},
	methods: {
		lastSelectedTab(tabsName: string) {
			return stateLastSelectedTab[tabsName];
		},
		startTabsView() {
			if (
				import.meta.client &&
				window.location.hash &&
				this.findTab(window.location.hash.substr(1))
			) {
				if (this.selectTab(window.location.hash.substr(1))) {
					return;
				}
			}

			if (this.options?.defaultTabHash && this.findTab(this.options.defaultTabHash)) {
				if (this.selectTab(this.options.defaultTabHash)) {
					return;
				}
			}

			const lastSelectedTab = this.lastSelectedTab(this.name);

			if (lastSelectedTab) {
				if (this.resetTabs) {
					if (this.selectTab(this.computeHashForTab(this.elements[0] as any))) {
						return;
					}
				} else if (this.selectTab(lastSelectedTab)) {
					return;
				}
			}

			// select first tab by default
			if (this.elements.length) {
				this.selectTab(this.computeHashForTab(this.elements[0] as any));
				return;
			}

			console.warn('tabs: no selectable tab found');
		},
		computeHeaderForTab(obj) {
			return (obj.prefix || '') + obj.name + (obj.suffix || '');
		},
		computeHashForTab(obj: { hash?: string; id?: string; name: string } | undefined) {
			if (obj && !obj.name) {
				return undefined;
			}
			return obj && (obj.hash || (obj.id ? obj.id : obj.name.toLowerCase().replace(/ /g, '-')));
		},
		setUnsetHeight(value: boolean) {
			this.unsetHeight = value;
		},
		findTab(hash: string) {
			if (this.elements.length) {
				return this.elements.find(tab => this.computeHashForTab(tab) === hash);
			}

			return undefined;
		},
		goToLeft() {
			// don't register touch event if there is an open modal
			const openModal = document.querySelector('.v--modal');
			if (openModal) {
				return;
			}
			const currentIndex = this.getActiveTabIndex();
			const leftTab = this.getTabHash(currentIndex - 1);
			if (leftTab && !this.blockTabChange) {
				this.selectTab(leftTab);
			}
		},
		goToRight() {
			// don't register touch event if there is an open modal
			const openModal = document.querySelector('.v--modal');
			if (openModal) {
				return;
			}
			const currentIndex = this.getActiveTabIndex();
			const rightTab = this.getTabHash(currentIndex + 1);
			if (rightTab && !this.blockTabChange) {
				this.selectTab(rightTab);
			}
		},
		selectTab(selectedTabHash?: string, event?: Event) {
			this.blockTabChange = false;

			// See if we should store the hash in the url fragment.
			if (event) {
				event.preventDefault();
			}

			const selectedTab = selectedTabHash && this.findTab(selectedTabHash);

			if (!selectedTab) {
				return false;
			}

			if (isTabComponent(selectedTab) && selectedTab.isDisabled) {
				if (event) {
					event.preventDefault();
				}
				return false;
			}

			if (this.lastActiveTabHash === this.getTabHash(selectedTab)) {
				this.$emit('clicked', { tab: selectedTab });
			} else {
				const direction = this.getTabIndex(selectedTabHash!) - this.getActiveTabIndex();

				if (direction < 0 && event) {
					this.direction = 'left';
				} else if (direction > 0 && event) {
					this.direction = 'right';
				}

				this.$emit('changed', { tab: selectedTab });
			}

			this.setActiveTab(selectedTabHash);

			// console.log('set active tab', selectedTabHash);
			this.lastActiveTabHash = this.activeTabHash;

			if (event) {
				// only if event (e.g. a click) has been provided
				stateLastSelectedTab[this.name] = this.getTabHash(selectedTab);
			}

			if (
				import.meta.client &&
				event &&
				isTabComponent(selectedTab) &&
				selectedTab.url &&
				window.location.pathname !== selectedTab.url &&
				window.location.protocol !== 'file:'
			) {
				window.history.replaceState({}, '', selectedTab.url);
			}

			return true;
		},
		getTabIndex(hash: string): number {
			return hash ? this.elements.findIndex(t => this.computeHashForTab(t as any) === hash) : -1;
		},
		getTabHash(index) {
			const tab = this.elements[index];

			if (!tab) {
				return false;
			}

			return this.computeHashForTab(tab as any);
		},
		getActiveTabIndex() {
			return this.getTabIndex(this.activeTabHash);
		},
		setActiveTab(selectedTabHash: string | undefined) {
			if (!selectedTabHash) {
				return;
			}

			this.activeTabHash = selectedTabHash;
			this.activeTabIndex = this.getTabIndex(selectedTabHash);

			if (this.$refs.tabs && this.$refs.tabs.length > 0) {
				// reactive way, the easy part
				this.$refs.tabs.forEach(singleTab => {
					if (isTabComponent(singleTab)) {
						singleTab.isActive = this.computeHashForTab(singleTab as any) === selectedTabHash;
					}
				});
				return;
			}

			if (this.$slots.default() && this.$slots.default().length > 0) {
				// inject property initialActive for constructor (this is necessary for SSR - but do also on client to match dom tree)
				this.$slots.default().forEach(slot => {
					if (slot.props) {
						const singleTab = slot.props as any;
						if (this.computeHashForTab(singleTab) === selectedTabHash) {
							singleTab.initialActive = true;
						}
					}
				});
				return;
			}
			console.warn('tabs: cannot active active tab');
		},
		onTabChange(oldVal, newVal) {
			// prevent emit on initial load
			if (oldVal && newVal) {
				this.$emit('tab-changed', this.activeTabHash);
			}
		}
	},
	props: {
		options: { type: Object, required: false, default: () => ({ defaultTabHash: null }) },
		tablistClass: { type: [String, Array, Object], required: false, default: () => [] },
		name: { type: String, required: true },
		headline: { type: String },
		resetTabs: { type: Boolean, default: false },
		alignCenter: { type: Boolean, default: false },
		smallSpacing: { type: Boolean, default: false }
	},
	watch: {
		activeTabHash: [
			{
				handler: 'onTabChange'
			}
		]
	}
});
</script>

<style lang="scss" scoped>
.fullwidth2tabsonphone {
	@media (max-width: $md) {
		li {
			width: 50%;
			text-align: center;

			a {
				padding-left: 0px !important;
				padding-right: 0px !important;
			}
		}
	}
}

.fullwidth2point5tabsonphone {
	@media (max-width: $md) {
		li {
			width: 40%;
			text-align: center;

			a {
				padding-left: 0px !important;
				padding-right: 0px !important;
			}
		}
	}
}

.fullwidth3tabsonphone {
	@media (max-width: $md) {
		li {
			width: 33.333%;
			text-align: center;

			a {
				padding-left: 0px !important;
				padding-right: 0px !important;
			}
		}
	}
}

.gradient {
	display: none; /* todo: @siml menu-overlay */
	pointer-events: none;
	position: fixed;
	right: 0;
	top: 65px;
	height: 44px;
	width: 70px;
	z-index: 2000;
	background: -moz-linear-gradient(
		left,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.01) 1%,
		rgba(255, 255, 255, 1) 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		left,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.01) 1%,
		rgba(255, 255, 255, 1) 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.01) 1%,
		rgba(255, 255, 255, 1) 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1); /* IE6-9 */
	@media (max-width: $md) {
		height: 38px;
		top: 50px;
	}
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
body.mobile {
	.tabs-component {
		ul.tabs-component-tabs {
			flex: 0;

			.tabs-header-container {
				overflow-x: scroll;
				scroll-behavior: smooth;
				margin-bottom: -1px; // smooth connecting borderbottom & active tab
				-ms-overflow-style: none; // IE 10+
				overflow: -moz-scrollbars-none; // Firefox

				&::-webkit-scrollbar {
					display: none; // Safari and Chrome
				}
			}
		}
	}
}

.tabs-component-panels {
	overflow: auto;
	display: flex;
	flex: 1;
}

.tabs-component {
	display: flex;
	flex-direction: column;

	ul.tabs-component-tabs {
		background-color: #ffffff;
		border-bottom: 1px solid $color-grey-light;
		position: relative;
		@media (max-width: $md) {
			width: 100%;
			white-space: nowrap;
		}

		li.tabs-component-tab {
			position: relative;
			display: inline-block;

			.only-2-tabs {
				@media (max-width: $md) {
					width: 50%;
				}
			}

			&.is-active a {
				color: #0fb1af;
				font-weight: bold;
				&::after {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 3px;
					background: #0fb1af;
					content: '';
				}
			}

			&.is-disabled {
				opacity: 0.4;
			}

			.tabs-component-tab-a {
				text-decoration: none;
			}

			a {
				color: $color-text;
				text-align: center;
				padding: 0.75rem 1rem; // better spacing 4 more tabs
				display: inline-block;
				overflow-x: auto;
				@media (min-width: $md) {
					padding: 0.75rem 2.5rem;
				}
				&.smallspacing {
					padding: 0.75rem 1.5rem;
				}
			}

			.badge {
				position: relative;
				background-color: $color-purple;
				color: #ffffff;
				border-radius: 50%;
				font-weight: bold;
				font-size: 12px;
				text-align: center;
				line-height: 19px;
				width: 20px;
				height: 20px;
				display: inherit;
				top: -2px;
				margin-left: 8px;

				&.notification-badge {
					width: 5px;
					height: 5px;
					margin-left: -2px;
					top: -12px;
				}

				&.info-badge {
					background: $color-grey-lightest;
					color: $color-grey;
				}

				&.text-badge {
					width: auto;
					font-size: $font-xs;
					border-radius: 6px;
					padding: 3px 7px;
					display: inline;
				}
			}
		}
	}
}

.fill-main {
	fill: #0fb1af;
}
</style>
