import { onTopLevelDomainChange } from '@hokify/tracking-nuxt3';
import { useUserRootStore } from '@/stores/root';

export default defineNuxtRouteMiddleware(async () => {
	const host = getHost();
	if (!host?.length) {
		return;
	}

	const {
		public: { development, ssl }
	} = useRuntimeConfig();

	const origin = !development || ssl ? `https://${host}` : `http://${host}`;
	const topLevelDomain = extractTopLevelDomain(origin);

	const userRootStore = useUserRootStore();
	userRootStore.setTopLevelDomain(topLevelDomain);
	if (import.meta.client && topLevelDomain) {
		await onTopLevelDomainChange(topLevelDomain);
	}

	userRootStore.setHost(origin);

	const loginStore = useLoginStore();
	loginStore.setAuthenticationRequired(false);
});

const supportedDomains = ['at', 'de', 'ch'];
function extractTopLevelDomain(origin: string) {
	const pathUrl = new URL(origin);
	const domainCode = (pathUrl.hostname || '').toLowerCase().split('.').pop();

	if (!supportedDomains.includes(domainCode || '')) {
		return undefined;
	}

	return domainCode;
}

function getHost() {
	if (import.meta.server) {
		return tryUseNuxtApp?.()?.ssrContext?.event?.node?.req?.headers?.host;
	}

	return window.location.host;
}
